// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer, { ApplicationActions, ApplicationState } from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import { layoutInitialState } from '../reducers/layout';
import { navbarInitialState } from '../reducers/navbar';
import { dishesInitialState } from 'src/views/food/reducer';
import { ApiService } from 'src/services/ApiService';
import { bankingInitialState } from 'src/views/banking/reducer';
import { productsInitialState } from 'src/views/products/reducer';
import { budgetInitialState } from 'src/views/budget/reducer';
import { spotifyInitialState } from 'src/views/spotify/reducer';
import { wurdInitialState } from 'src/views/wurd/wurdReducer';
import { userStoreInitialState } from 'src/views/user/reducer';
import { tasksInitialState } from 'src/views/tasks/reducer';

const apiService = new ApiService()

export type ThunkExtraArguments = {
    apiService: ApiService
}

// ** init middleware
const middleware = [thunk.withExtraArgument({ apiService }), createDebounce()]

// ** Dev Tools
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
// const store = createStore<ApplicationState>(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))

const store = createStore<ApplicationState, ApplicationActions, unknown, unknown>(rootReducer,
    {
        food: dishesInitialState,
        navbar: navbarInitialState,
        layout: layoutInitialState,
        banking: bankingInitialState,
        products: productsInitialState,
        budget: budgetInitialState,
        spotify: spotifyInitialState,
        wurd: wurdInitialState,
        user: userStoreInitialState,
        tasks: tasksInitialState
    },
    composeWithDevTools(applyMiddleware(...middleware)))
export { store }


export type AppDispatch = typeof store.dispatch