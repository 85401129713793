import { PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { BankingStoreState, BankingThunk } from "../../types"



export const UTILITIES_IMPORT_ETL_POST = 'UTILITIES_IMPORT_ETL_POST'

export interface UtilitiesImportEtlPostParams {
    etlId: number

}

export const utilitiesImportEtlPost = (params: UtilitiesImportEtlPostParams): BankingThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/utilities/import/etl' 
        path = concatApiUrl(path, params.etlId, "etlId")

        return callPostDispatchedApiSameName<string, BankingStoreState, any>(
            apiService, path, dispatch, UTILITIES_IMPORT_ETL_POST, {});

    }
}

export interface UtilitiesImportEtlPostStoreFields extends PostObjectStoreFields<string> { }

export interface UtilitiesImportEtlPostAction extends UtilitiesImportEtlPostStoreFields {
    type: typeof UTILITIES_IMPORT_ETL_POST
}