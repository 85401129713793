import { PaginatedListParams } from "src/interfaces/PaginatedList"
import { callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { TasksStoreState, TasksThunk } from "../../types"
import { ApplicationState } from "src/redux/reducers/rootReducer"



export interface GoogleTaskDTO {
    completed?: Date
    deleted: boolean
    due?: Date
    hidden: boolean
    id: string
    notes?: string
    status?: string
    title: string
    updated: Date
    webViewLink: string
}

export interface GoogleTaskDTOPaginatedList {
    pageIndex: number
    totalPages: number
    totalCount: number
    hasPreviousPage: boolean
    hasNextPage: boolean
    items: GoogleTaskDTO[]
}

export const TASKS_GET = 'TASKS_GET'

export interface TasksGetParams extends PaginatedListParams {
    textLikeFilter?: string
    reuseStoreCachedParameters?: boolean
}


export const tasksGet = (params: TasksGetParams): TasksThunk => {
    return (dispatch, getStore, { apiService }) => {


        let params_temp = params;

        if (params.reuseStoreCachedParameters === true) {
            const state = getStore() as unknown as ApplicationState
            params_temp = { ...state.tasks.search.tasks_get?.params, ...params }; // pierwsze w kolejności idą zcacheowane wartości, później nowe

        } else
            params_temp = params;

        var path: string = '/tasks'
        if (params_temp.pageNumber === undefined)
            path += '?pageNumber=1'
        else
            path += '?pageNumber=' + params_temp.pageNumber

        if (params_temp.pageSize !== undefined)
            path += '&pageSize=' + params_temp.pageSize

        if (params_temp.textLikeFilter !== undefined && params_temp.textLikeFilter.length > 0)
            path += '&textLikeFilter=' + params_temp.textLikeFilter


        return callGetDispatchedApiSameName<GoogleTaskDTOPaginatedList, TasksStoreState, void, TasksGetParams>(
            apiService, path, dispatch, TASKS_GET, undefined, params_temp);

    }
}

export interface TasksGetStoreFields extends GetObjectStoreFieldsWithParams<GoogleTaskDTOPaginatedList, TasksGetParams> { }

export interface TasksGetAction extends TasksGetStoreFields {
    type: typeof TASKS_GET
}

