import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { DishRecipeDTO } from "../../dish/store/types"
import { FoodStoreState, FoodThunk } from "../../types"
import { MealsPlanDTO } from "./mealsPlanGet"



export interface MealsPlanDetailedDTO extends MealsPlanDTO {
    dishes: DishRecipeDTO[]
}

/// MEALS_PLAN_GET_DETAILED

export const MEALS_PLAN_GET_DETAILED = 'MEALS_PLAN_GET_DETAILED'

export interface MealsPlanGetDetailedParams extends ApiCallBaseData {
    id: number

}

export const mealsPlanGetDetailed = (params: MealsPlanGetDetailedParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/' + params.id + '/detailed'

        return callGetDispatchedApiSameName<MealsPlanDetailedDTO, FoodStoreState, void, MealsPlanGetDetailedParams>(
            apiService, path, dispatch, MEALS_PLAN_GET_DETAILED, undefined, params);

    }
}

export interface MealsPlanGetDetailedStoreFields extends GetObjectStoreFieldsWithParams<MealsPlanDetailedDTO, MealsPlanGetDetailedParams> { }

export interface MealsPlanGetDetailedAction extends MealsPlanGetDetailedStoreFields {
    type: typeof MEALS_PLAN_GET_DETAILED
}