import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName, callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk } from "../../types"


export interface ListItemPostResponse {
    name: string;
    amount: string;
    unit: string;
    creator: string;
    creatorUsername: string;
    categoryId: number;
    checked: number;
    creationDate: string;
    deleted: number;
    defaultImageUrl: string;
    id: string;
    url: string;
}

export interface ListonicPostItemsDTO {
    items: ListItemPostResponse[]
}

export const MEALS_PLAN_SHOPPING_LIST_LISTONIC_POST = 'MEALS_PLAN_SHOPPING_LIST_LISTONIC_POST'

export interface MealsPlanShoppingListListonicPostParams extends ApiCallBaseData {
    id: number

}

export const mealsPlanShoppingListListonicPost = (params: MealsPlanShoppingListListonicPostParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/' + params.id + '/shopping_list/listonic'

        return callPostDispatchedApiSameName<ListonicPostItemsDTO, FoodStoreState, string, MealsPlanShoppingListListonicPostParams>(
            apiService, path, dispatch, MEALS_PLAN_SHOPPING_LIST_LISTONIC_POST, "EMPTY", params);

    }
}

export interface MealsPlanShoppingListListonicPostStoreFields extends GetObjectStoreFieldsWithParams<ListonicPostItemsDTO, MealsPlanShoppingListListonicPostParams> { }

export interface MealsPlanShoppingListListonicPostAction extends MealsPlanShoppingListListonicPostStoreFields {
    type: typeof MEALS_PLAN_SHOPPING_LIST_LISTONIC_POST
}