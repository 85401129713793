import { PatchObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callPatchDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { WordDTO } from "../../words_list/store/wordsListTypes"
import { WurdStoreState, WurdThunk } from "../../wurdStoreTypes"
import { concatApiUrl } from "src/utility/converters"




/// WORD_WRONG_PATCH

export const WORD_WRONG_PATCH = 'WORD_WRONG_PATCH'

export interface WordWrongPatchParams extends ApiCallBaseData {
    wordId: number
    isWrong: boolean

}

export const wordWrongPatch = (params: WordWrongPatchParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/word/wrong'
        path = concatApiUrl(path, params.wordId, "wordId")
        path = concatApiUrl(path, params.isWrong, "isWrong")
        
        return callPatchDispatchedApiSameName<WordDTO, WurdStoreState, null, WordWrongPatchParams>(
            apiService, path, dispatch, WORD_WRONG_PATCH, null, params);

    }
}

export interface WordWrongPatchStoreFields extends PatchObjectStoreFields<WordDTO> { }

export interface WordWrongPatchAction extends WordWrongPatchStoreFields {
    type: typeof WORD_WRONG_PATCH
}


