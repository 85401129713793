
import { callSetSameName } from "src/utility/DispatchWrappers"
import { UserStoreState, UserThunk } from "../types"




export interface UserRolesStoreFields {
    roles?: string[] // role jakie przekazał nam system uwierzytelniający
}
export const USER_ROLES = 'USER_ROLES'



export const setUserRoles = (data: UserRolesStoreFields): UserThunk => {
    return (dispatch) => {
        callSetSameName<UserRolesStoreFields, UserStoreState>(data, dispatch, USER_ROLES)
    }
}

export interface SetUserRolesAction {
    type: typeof USER_ROLES
    object: UserRolesStoreFields
}

