import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThunkExtraArguments } from "../storeConfig/store";


/**
 * @typeParam  I  Rodzaj enuma akcji danego substore
 * @typeParam  S  Rodzaj danego substore
 */
export function clearPartialGeneric<T, S>(
    part: T,
    keysMap: Map<T, string>,
    clearActionName: string,
    dispatch: ThunkDispatch<S, ThunkExtraArguments, Action<string>>) {

    let overridedObject = {} as any;
    const key = keysMap.get(part)
    if (key === undefined)
        console.error("Nie udało się znaleść klucza do czyszczenia sekcji store dla akcji " + part);
    else
        overridedObject[key] = undefined

    dispatch({
        type: clearActionName,
        object: {
            ...overridedObject
        },
    })
}

/**
 * @typeParam  S  Rodzaj danego substore
 */
 export function clearPartialSameNameGeneric<S>(
    actionToClearName: string,
    storeClearActionName: string,
    dispatch: ThunkDispatch<S, ThunkExtraArguments, Action<string>>) {

    let overridedObject = {} as any;
    const key = actionToClearName.toLowerCase()

        overridedObject[key] = undefined

    dispatch({
        type: storeClearActionName,
        object: {
            ...overridedObject
        },
    })
}