import { PaginatedList, PaginatedListParams } from "src/interfaces/PaginatedList"
import { GetObjectStoreFields, GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { ApiCallBaseData, callGetDispatchedApi, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { RequirementDTO } from "../../product/store/ProductRequirementsTypes"
import { ProductsStoreState, ProductsThunk } from "../../types"


/// REQUIREMENT_GET

export const REQUIREMENT_GET = 'REQUIREMENT_GET'

export interface RequirementGetParams extends ApiCallBaseData {
    id: number

}

export const requirementGet = (params: RequirementGetParams): ProductsThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/requirements/' + params.id

        return callGetDispatchedApiSameName<RequirementDTO, ProductsStoreState, void, RequirementGetParams>(
            apiService, path, dispatch, REQUIREMENT_GET, undefined, params);

    }
}

export interface RequirementGetStoreFields extends GetObjectStoreFieldsWithParams<RequirementDTO, RequirementGetParams> { }

export interface RequirementGetAction extends RequirementGetStoreFields {
    type: typeof REQUIREMENT_GET
}