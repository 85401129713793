import { PaginatedList, PaginatedListParams } from "src/interfaces/PaginatedList"
import { GetObjectStoreFieldsWithParams, PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { callGetDispatchedApi, callGetDispatchedApiSameName, callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO, ProductsValues } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk, FoodThunkDispatch } from "../../types"

/// PRODUCT_POST

export interface ProductUpdateableDTO extends ProductsValues {}

export const PRODUCT_POST = 'PRODUCT_POST'

export interface ProductPostParams {
    dto: ProductUpdateableDTO

}

export const productPost = (params: ProductPostParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/product' 

        return callPostDispatchedApiSameName<ProductsDTO, FoodStoreState, ProductUpdateableDTO>(
            apiService, path, dispatch, PRODUCT_POST, params.dto);

    }
}

export interface ProductPostStoreFields extends PostObjectStoreFields<ProductsDTO> { }

export interface ProductPostAction extends ProductPostStoreFields {
    type: typeof PRODUCT_POST
}