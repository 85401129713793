import { PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodThunk, FoodStoreState } from "src/views/food/types"
import { ProductUnitConversionDTO } from "./productConversionListGet"
import { ProductUnitConversionUpdateableDTO } from "./productConversionPost"



/// PRODUCT_CONVERSION_PUT


export const PRODUCT_CONVERSION_PUT = 'PRODUCT_CONVERSION_PUT'

export interface ProductConversionPutParams {
    id: number
    dto: ProductUnitConversionUpdateableDTO

}

export const productConversionPut = (params: ProductConversionPutParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/product/' + params.dto.productId + '/conversion/' + params.id

        return callPutDispatchedApiSameName<ProductUnitConversionDTO, FoodStoreState, ProductUnitConversionUpdateableDTO>(
            apiService, path, dispatch, PRODUCT_CONVERSION_PUT, params.dto);

    }
}

export interface ProductConversionPutStoreFields extends PutObjectStoreFields<ProductUnitConversionDTO> { }

export interface ProductConversionPutAction extends ProductConversionPutStoreFields {
    type: typeof PRODUCT_CONVERSION_PUT
}