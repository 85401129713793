export type OptionType = {
    value: string
    label: string
};

export type GroupedOptionType = {
    label: string
    options: OptionType[]
}

export type NumericOptionType = {
    // value: string
    id: number
    label: string
}

export type GroupedNumericOptionType = {
    label: string
    options: NumericOptionType[]
}




export class ReactSelectNumericOptionType {

    value: string
    id: number
    label: string

    constructor(id: number, label: string) {
        this.value = id.toString()
        this.id = id
        this.label = label
    }
}

export type ReactSelectGroupedNumericOptionType = {
    label: string
    options: ReactSelectNumericOptionType[]
}
