import { DeleteObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callDeleteDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { WurdStoreState, WurdThunk } from "../../wurdStoreTypes"

/// WORDS_PAIR_DELETE


export const WORDS_PAIR_DELETE = 'WORDS_PAIR_DELETE'

export interface WordsPairDeleteParams extends ApiCallBaseData {
    id: number
}

export const wordsPairDelete = (params: WordsPairDeleteParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/words_pair/' + params.id

        return callDeleteDispatchedApiSameName<WurdStoreState>(
            apiService, path, dispatch, WORDS_PAIR_DELETE, params.id);

    }
}

export interface WordsPairDeleteStoreFields extends DeleteObjectStoreFields { }

export interface WordsPairDeleteAction extends WordsPairDeleteStoreFields {
    type: typeof WORDS_PAIR_DELETE
}