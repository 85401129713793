import { ReactSelectGroupedNumericOptionType, ReactSelectNumericOptionType } from "../../shared/types/SelectOptionTypes";
import { BankingCategoryGroupDTO } from "./bankingCategoriesGet";


export interface BankingCategoryDictValue {
    name: string
    isPositive: boolean
    groupTitle: string
}

export class BankingCategoryDictsMapped {
    income: BankingCategoryGroupDTO[];
    outcome: BankingCategoryGroupDTO[];

    incomeSelectList: ReactSelectGroupedNumericOptionType[];
    outcomeSelectList: ReactSelectGroupedNumericOptionType[];


    nameDict: Map<number, BankingCategoryDictValue>; // id -> categoryName

    constructor(income: BankingCategoryGroupDTO[], outcome: BankingCategoryGroupDTO[]) {
        this.income = income;
        this.outcome = outcome;

        const m = new Map<number, BankingCategoryDictValue>();
        [income, outcome].forEach((s, i) => {
            s.forEach(g => {
                g.data.forEach(cat => {
                    m.set(cat.id, {
                        name: cat.name,
                        isPositive: i === 0,
                        groupTitle: g.groupTitle
                    });
                });
            });
        });
        this.nameDict = m;
        this.incomeSelectList = this.mapCategoriesToListOfReactSelect(income);
        this.outcomeSelectList = this.mapCategoriesToListOfReactSelect(outcome);
    }

    mapCategoriesToListOfReactSelect = (l: BankingCategoryGroupDTO[]): ReactSelectGroupedNumericOptionType[] => {
        return l.map(
            x => ({
                label: x.groupTitle,
                options: x.data.map(
                    y => (new ReactSelectNumericOptionType(y.id, y.name)))
            } as ReactSelectGroupedNumericOptionType));
    }
}