import { Reducer } from 'redux'
import { budgetMonthlyInitialState, CLEAR_BUDGET, SAVE_BUDGET_PAGE_DATA } from './monthly/store/types'
import { BudgetActionTypes, BudgetStoreState } from './types'
import { reducerStateModifySubstore } from 'src/redux/reducers/reducerStateModifySubstore'
import { BUDGET_FORECAST_GET } from './monthly/store/budgetForecastGet'
import { BUDGET_PLANS_GET } from './monthly/store/budgetPlansGet'
import { BUDGET_PLAN_PUT } from './monthly/store/budgetPlanPut'
import { BUDGET_PLANS_POST } from './monthly/store/budgetPlansPost'
import { BUDGET_PLAN_DELETE } from './monthly/store/budgetPlanDelete'
import { BUDGET_PLANS_MONTH_GET } from './monthly/store/budgetPlansMonthGet'


export const budgetInitialState: BudgetStoreState = {
    monthly: budgetMonthlyInitialState,
}

export const MonthlyActionNames: string[] = [BUDGET_FORECAST_GET, BUDGET_PLANS_GET, BUDGET_PLAN_PUT, BUDGET_PLANS_POST,
    BUDGET_PLAN_DELETE, CLEAR_BUDGET, BUDGET_PLANS_MONTH_GET]


const budgetReducer: Reducer<BudgetStoreState, BudgetActionTypes> = (state = budgetInitialState, action: BudgetActionTypes) => {
    if (MonthlyActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "monthly")
    switch (action.type) {
        case SAVE_BUDGET_PAGE_DATA:
            return {
                ...state,
                monthly: {
                    ...state.monthly,
                    page_data: {
                        ...state.monthly.page_data,
                        ...action.object
                    }
                }
            }
        default:
            return state
    }
}

export default budgetReducer

