import { Reducer } from 'redux'
import { bankingDictionariesInitialState, DICTS_WURD_GET } from './dicts/store/types'
import { WurdActionTypes, WurdStoreState } from './wurdStoreTypes'
import { GET_WURD_FICHES_LIST, GET_WURD_WORDS_LISTS, WORDS_LIST_GET, PUT_WURD_FICHE, PUT_WURD_FICHES_LIST, wurdWordsListInitialState, WORDS_LIST_POST, FICHES_LIST_POST, CLEAR_WURD_WORDS_LIST } from './words_list/store/wordsListTypes'
import { CLEAR_WURD_WORD, wurdWordsInitialState } from './words/store/wordsTypes'
import { reducerStateModifySubstore } from 'src/redux/reducers/reducerStateModifySubstore'
import { WORDS_GET } from './words/store/wordsGet'
import { WORDS_AUTOSUGGEST_GET } from './words/store/wordsAutosuggestGet'
import { WORD_POST } from './words/store/wordPost'
import { WORDS_PAIR_POST } from './words/store/wordsPairPost'
import { WORD_PUT } from './words/store/wordPut'
import { WORD_DUPLICATE_GET } from './words/store/wordDuplicateGet'
import { WORD_DUPLICATE_CONSOLIDATE_POST } from './words/store/wordDuplicateConsolidatePost'
import { WORD_GET } from './words/store/wordGet'
import { WORD_CONNECTED_GET } from './words/store/wordConnectedGet'
import { WORDS_PAIR_DELETE } from './words/store/wordsPairDelete'
import { WORD_DELETE } from './words/store/wordDelete'
import { FICHES_LIST_DASHBOARD_GENERAL_GET } from './words_list/store/fichesListDashboardGeneralGet'
import { FICHES_LIST_DELETE } from './words_list/store/fichesListDelete'
import { WORD_WRONG_PATCH } from './words/store/wordWrongPatch'


export const wurdInitialState: WurdStoreState = {
    words_list: wurdWordsListInitialState,
    dicts: bankingDictionariesInitialState,
    words: wurdWordsInitialState
}


export const WurdWordActionNames: string[] =
    [WORD_GET, WORDS_GET, WORDS_AUTOSUGGEST_GET, WORD_POST, WORD_PUT, WORD_DELETE,
        WORDS_PAIR_POST, WORDS_PAIR_DELETE,
        WORD_DUPLICATE_CONSOLIDATE_POST, WORD_DUPLICATE_GET,
        WORD_CONNECTED_GET, CLEAR_WURD_WORD, WORD_WRONG_PATCH]

export const WurdWordsListActionNames: string[] =
    [FICHES_LIST_DASHBOARD_GENERAL_GET, GET_WURD_WORDS_LISTS, WORDS_LIST_GET, WORDS_LIST_POST,
        PUT_WURD_FICHES_LIST, FICHES_LIST_POST, PUT_WURD_FICHE, CLEAR_WURD_WORDS_LIST,
        FICHES_LIST_DELETE, GET_WURD_FICHES_LIST]

export const WurdFichesListActionNames: string[] =
    []

const wurdReducer: Reducer<WurdStoreState, WurdActionTypes> = (state = wurdInitialState, action: WurdActionTypes) => {
    if (WurdWordActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "words")
    else if (WurdWordsListActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "words_list")
    else
        switch (action.type) {
            // DICTS
            case DICTS_WURD_GET:
                return reducerStateModifySubstore(state, action, "dicts")
            default:
                return state
        }
}

export default wurdReducer

