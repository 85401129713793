export class Constants {
  public static stsAuthority = (window as any)._env_.KRAVIS_ONE_WEB_STS_AUTHORITY;
  public static clientId = (window as any)._env_.KRAVIS_ONE_WEB_CLIENTID;
  public static clientRoot = (window as any)._env_.KRAVIS_ONE_WEB_CLIENTROOT;
  public static clientScope = 'openid profile email KravisOneApi KravisOneScrapperAPI';
  public static googleClientId = (window as any)._env_.KRAVIS_ONE_WEB_GOOGLE_CLIENT_ID;

  public static apiRoot = (window as any)._env_.KRAVIS_ONE_WEB_API_URL.replace(/\/$/, "");
}

export class WordPairsFormIds {
  public static DWDS = "dwdsToggleId";
  public static PONS = "ponsToggleId";
  public static GetTypedTextId = (langId: number) => langId + "TypedText";
  public static GetSelectedSuggestionId = (langId: number) => langId + "SelectedSuggestion";
  public static GetPartOfSpeechId = (langId: number) => langId + "PartOfSpeech";
  // public static GetTypingId = (langId: number) => langId + "Typing";
  public static GetWordCreatedId = (langId: number) => langId + "WordCreated";
  public static GetAttributeFieldId = (langId: number) => langId + "Attribute";
}


