import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk } from "../../types"

interface MealsPlanIngredientBuyEntryOriginDTO {
    dishId: number
    dishName: string
    unitId: number
    mealsPlanEntryId: number
    dishVariantId: number
    dishVariantName: string
    quantity: number
}

interface MealsPlanIngredientBuyEntryDTO {
    productId: number
    productName: string
    unitId: number
    categoryId: number
    totalQuantity: number
    entries: MealsPlanIngredientBuyEntryOriginDTO[]
}

export interface MealsPlanShoppingListDTO {
    entries: MealsPlanIngredientBuyEntryDTO[]
}

/// MEALS_PLAN_GET_SHOPPING_LIST

export const MEALS_PLAN_SHOPPING_LIST_GET = 'MEALS_PLAN_SHOPPING_LIST_GET'

export interface MealsPlanGetShoppingListParams extends ApiCallBaseData {
    id: number

}

export const mealsPlanGetShoppingList = (params: MealsPlanGetShoppingListParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/' + params.id + '/shopping_list'

        return callGetDispatchedApiSameName<MealsPlanShoppingListDTO, FoodStoreState, void, MealsPlanGetShoppingListParams>(
            apiService, path, dispatch, MEALS_PLAN_SHOPPING_LIST_GET, undefined, params);

    }
}

export interface MealsPlanGetShoppingListStoreFields extends GetObjectStoreFieldsWithParams<MealsPlanShoppingListDTO, MealsPlanGetShoppingListParams> { }

export interface MealsPlanGetShoppingListAction extends MealsPlanGetShoppingListStoreFields {
    type: typeof MEALS_PLAN_SHOPPING_LIST_GET
}