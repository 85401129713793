import { PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO, ProductsValues } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk } from "../../types"
import { preparePostPutBody } from "./actions"

import { DishRecipeDTO, DishUploadDTO } from "./types"

/// DISH_POST

export interface DishRichTextUpdateDTO extends DishUploadDTO {
    // recipeRichText: string
}

export const DISH_POST = 'DISH_POST'

export interface DishPostParams {
    dto: DishRichTextUpdateDTO

}

export const dishPost = (params: DishPostParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/dishes/';
        
        let formData = preparePostPutBody(params.dto)
        return callPostDispatchedApiSameName<ProductsDTO, FoodStoreState, FormData>(
            apiService, path, dispatch, DISH_POST, formData);

    }
}

export interface DishPostStoreFields extends PostObjectStoreFields<DishRecipeDTO> { }

export interface DishPostAction extends DishPostStoreFields {
    type: typeof DISH_POST
}