import { DeleteObjectStoreFields, GetObjectStoreFields, PostObjectStoreFields, PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiError } from "src/services/ApiService"
import { FinancialProductHistoryDTO } from "./ProductGeneralTypes"


export interface FinancialProductHistoryUpdateDTO {
    dated: string
    financialProductHistoryTypeId: number
    originOverride?: string
}

export interface FinancialProductHistoryAddDTO extends FinancialProductHistoryUpdateDTO {
    financialProductId: number
}


// POST

export const POST_PRODUCT_HISTORY = 'POST_PRODUCT_HISTORY'

export interface PostFinancialProductHistoryFields extends PostObjectStoreFields<FinancialProductHistoryDTO> { }

export interface PostFinancialProductHistoryAction extends PostFinancialProductHistoryFields {
    type: typeof POST_PRODUCT_HISTORY
}

// PUT

export const PUT_PRODUCT_HISTORY = 'PUT_PRODUCT_HISTORY'

export interface PutFinancialProductHistoryFields extends PutObjectStoreFields<FinancialProductHistoryDTO> { }

export interface PutFinancialProductHistoryAction extends PutFinancialProductHistoryFields {
    type: typeof PUT_PRODUCT_HISTORY
}

// DELETE

export const DELETE_PRODUCT_HISTORY = 'DELETE_PRODUCT_HISTORY'

export interface DeleteFinancialProductHistoryFields extends DeleteObjectStoreFields { }

export interface DeleteFinancialProductHistoryAction extends DeleteFinancialProductHistoryFields {
    type: typeof DELETE_PRODUCT_HISTORY
}

// Clear

export const CLEAR_PRODUCT_HISTORY = 'CLEAR_PRODUCT_HISTORY'

export interface ClearFinancialProductHistoryAction{
    type: typeof CLEAR_PRODUCT_HISTORY
}

/// STORE

export interface ProductHistoryStoreState {
    post?: PostFinancialProductHistoryFields
    put?: PutFinancialProductHistoryFields
    delete?: DeleteFinancialProductHistoryFields
    // product_list?: GetFinancialProductListFields
    // addresses?: GetUserAddressesFields
    // person_datas?: GetUserPersonDatasFields
}


export const productHistoryInitialState: ProductHistoryStoreState = {
}

export type ProductHistoryActionTypes = PostFinancialProductHistoryAction | PutFinancialProductHistoryAction | DeleteFinancialProductHistoryAction | ClearFinancialProductHistoryAction;
