import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { WurdThunk, WurdStoreState } from "../../wurdStoreTypes"
import { WordsPaginatedDTO } from "./wordsGet"


/// WORDS_AUTOSUGGEST_GET

export const WORDS_AUTOSUGGEST_GET = 'WORDS_AUTOSUGGEST_GET'

export interface WordsAutosuggestGetParams extends ApiCallBaseData {
    textLikeFilter: string
    languageId: number
}

export const wordsAutosuggestGet = (params: WordsAutosuggestGetParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/word?pageNumber=1&pageSize=10&textLikeFilter=' + params.textLikeFilter + '&languageId=' + params.languageId

        return callGetDispatchedApiSameName<WordsPaginatedDTO, WurdStoreState, void, WordsAutosuggestGetParams>(
            apiService, path, dispatch, WORDS_AUTOSUGGEST_GET, undefined, params);

    }
}

export interface WordsAutosuggestGetStoreFields extends GetObjectStoreFieldsWithParams<WordsPaginatedDTO, WordsAutosuggestGetParams> { }

export interface WordsAutosuggestGetAction extends WordsAutosuggestGetStoreFields {
    type: typeof WORDS_AUTOSUGGEST_GET
}