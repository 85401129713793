import { GetObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults";
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers";
import { WurdStoreState, WurdThunk } from "../../wurdStoreTypes";
import { FichesListDTO } from "./wordsListTypes";


export const FICHES_LIST_DASHBOARD_GENERAL_GET = 'FICHES_LIST_DASHBOARD_GENERAL_GET'

export interface WurdDashboardGeneralDTO {
    recentFichesList: FichesListDTO
}

export const getWurdFichesListDashboardGeneral = (params: ApiCallBaseData): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/dashboard/general'

        return callGetDispatchedApiSameName<WurdDashboardGeneralDTO, WurdStoreState, void, ApiCallBaseData>(
            apiService, path, dispatch, FICHES_LIST_DASHBOARD_GENERAL_GET, undefined, params);

    }
}




export interface GetWurdFichesListDashboardGeneralStoreFields extends GetObjectStoreFields<WurdDashboardGeneralDTO> {
}

export interface GetWurdFichesListDashboardGeneralAction extends GetWurdFichesListDashboardGeneralStoreFields {
    type: typeof FICHES_LIST_DASHBOARD_GENERAL_GET
}