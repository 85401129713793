
import { callSetSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk } from "../../types"
import { ApplicationState } from "src/redux/reducers/rootReducer"


export interface DishMultiplierSetParams {
    dishId: number,
    multiplier: number
}

// Chcemy zapisyć historię wszystkich ustawień przeliczników z poprzednich wejść w przeglądarce
export interface DishMultiplierSetStoreFields {
    multipliersList: DishMultiplierSetParams[]
}
export const DISH_MULTIPLIER_SET = 'DISH_MULTIPLIER_SET'

export const dishMultiplierSet = (data: DishMultiplierSetParams): FoodThunk => {

    return (dispatch, getState) => {
        const state = getState() as unknown as ApplicationState
        var mm = state.food.dish.dish_multiplier_set?.multipliersList;
        if (mm === undefined)
            mm = []

        var f = mm.find(x => x.dishId === data.dishId);
        if (f === undefined)
            mm.push(data)
        else
            f.multiplier = data.multiplier;

        callSetSameName<DishMultiplierSetStoreFields, FoodStoreState>({ multipliersList: mm }, dispatch, DISH_MULTIPLIER_SET)
    }
}

export interface DishSetMultiplierAction {
    type: typeof DISH_MULTIPLIER_SET
    object: DishMultiplierSetStoreFields
}

