



import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { BudgetStoreState, BudgetThunk } from "../../types"
import { ApplicationState } from "src/redux/reducers/rootReducer"
import { BudgetPlansDTO } from "./budgetPlansPost"


// BUDGET_PLANS_MONTH_GET

export const BUDGET_PLANS_MONTH_GET = 'BUDGET_PLANS_MONTH_GET'

export interface BudgetPlansMonthGetParams extends ApiCallBaseData {
    month?: number
    year?: number
    categoryId?: number
}

export const budgetPlansMonthGet = (params: BudgetPlansMonthGetParams): BudgetThunk => {
    return (dispatch, getStore, { apiService }) => {

        var path: string = '/api/budget/plans'
        const state = getStore() as unknown as ApplicationState

        if (params.month == undefined)
            params.month = state.budget.monthly.budget_plans_month_get?.params?.month

        if (params.year == undefined)
            params.year = state.budget.monthly.budget_plans_month_get?.params?.year

        if (params.categoryId == undefined)
            params.categoryId = state.budget.monthly.budget_plans_month_get?.params?.categoryId


        var path: string = '/api/budget/plans/month'

        path += '?month=' + params.month
        path += '&year=' + params.year
        path += '&categoryId=' + params.categoryId

        return callGetDispatchedApiSameName<BudgetPlansDTO, BudgetStoreState, void, BudgetPlansMonthGetParams>(
            apiService, path, dispatch, BUDGET_PLANS_MONTH_GET, undefined, params);

    }
}

export interface BudgetPlansMonthGetStoreFields extends GetObjectStoreFieldsWithParams<BudgetPlansDTO, BudgetPlansMonthGetParams> { }

export interface BudgetPlansMonthGetAction extends BudgetPlansMonthGetStoreFields {
    type: typeof BUDGET_PLANS_MONTH_GET
}