import { ApiCallBaseData, callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { PutObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { BudgetStoreState, BudgetThunk } from "../../types"
import { BudgetPlanDTO, BudgetPlanUptadeableDTO } from "./budgetPlansPost"

export interface BudgetPlanUpdateDTO extends BudgetPlanUptadeableDTO {
}

export const BUDGET_PLAN_PUT = 'BUDGET_PLAN_PUT'

export interface BudgetPlanPutParams extends ApiCallBaseData {
    originBudgetPlanId: number
    dto: BudgetPlanUpdateDTO
}



export const budgetPlanPut = (params: BudgetPlanPutParams): BudgetThunk => {
    return (dispatch, _getStore, { apiService }) => {


        var path: string = '/api/budget/plan/' + params.originBudgetPlanId


        return callPutDispatchedApiSameName<BudgetPlanDTO, BudgetStoreState, BudgetPlanUpdateDTO, BudgetPlanPutParams>(
            apiService, path, dispatch, BUDGET_PLAN_PUT, params.dto, params);


    }
}

export interface BudgetPlanPutStoreFields extends PutObjectStoreFieldsWithParams<BudgetPlanUpdateDTO, BudgetPlanPutParams> { }

export interface BudgetPlanPutAction extends BudgetPlanPutStoreFields {
    type: typeof BUDGET_PLAN_PUT
}

