import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ApiErrorStoreField, CalledObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults";
import { ThunkExtraArguments } from "src/redux/storeConfig/store";
import { ApiCallBaseData } from "src/utility/DispatchWrappers";
import { DictBaseValues } from "src/utility/dropdown";
import { FinancialDictsMapped } from "./FinancialDictsMapped";

export interface DictionarySingleIntegerKey {
    id: number
}


export interface FinancialProductHistoryTypeValues {
    name: string
    origin?: string
    type?: string
    eventIcon?: string
    eventStatus?: string
}

export interface FinancialProductHistoryTypeDTO extends DictionarySingleIntegerKey, FinancialProductHistoryTypeValues { }

export interface FinancialProductTypeValues extends DictBaseValues {
    shortName: string
}

export interface FinancialProductTypeDTO extends DictionarySingleIntegerKey, FinancialProductTypeValues { }


export interface RequirementGroupTypeValues {
    name: string
}

export interface RequirementGroupTypeDTO extends DictionarySingleIntegerKey, RequirementGroupTypeValues { }


export interface RequirementTypeValues {
    requirementEndDate?: number
    requirementItemsCount?: number
    requirementItemsSum?: number
    requirementPeriodDaysLength?: number
    requirementPeriodCount?: number
    requirementFindText?: number
    requirementDateIdentifier?: number
    requirementDaysPostpone?: number
    name: string
    description: string
    requirementGroupTypeId: number // INFO: Zmieniono z niewymagalności na wymagalność
}

export interface RequirementTypeDTO extends DictionarySingleIntegerKey, RequirementTypeValues { }

export interface FinancialDictionariesDTO {
    financialProductHistoryTypes: FinancialProductHistoryTypeDTO[],
    financialProductTypes: FinancialProductTypeDTO[],
    requirementGroupTypes: RequirementGroupTypeDTO[]
    requirementTypes: RequirementTypeDTO[]

}


interface GetFinancialDictionariesActionStoreFields extends CalledObjectStoreFieldsWithParams<FinancialDictsMapped, ApiCallBaseData> { }

export interface FinancialDictionariesStoreState {
    dicts_financial_get?: GetFinancialDictionariesActionStoreFields
}

export const DICTS_FINANCIAL_GET = 'DICTS_FINANCIAL_GET'


export interface GetDictsFinancialAction extends GetFinancialDictionariesActionStoreFields {
    type: typeof DICTS_FINANCIAL_GET
}


export type FinancialDictionariesActionTypes = GetDictsFinancialAction

