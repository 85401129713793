import { PostObjectStoreFields, PostObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { MealsPlanEntryDTO } from "src/views/food/meals_plan/store/mealsPlanEntryPost"
import { WordAttributeUpdateDTO, WordDTO, WordsPairDTO } from "../../words_list/store/wordsListTypes"
import { WurdThunk, WurdStoreState } from "../../wurdStoreTypes"

export interface WordsPairUpdateDTO {
    wordsListId: number
    wordLanguageLearnId: number
    wordLanguageOriginId: number
}

/// WORDS_PAIR_POST

export const WORDS_PAIR_POST = 'WORDS_PAIR_POST'

export interface WordsPairPostParams extends ApiCallBaseData  {
    dto: WordsPairUpdateDTO
}

export const wordsPairPost = (params: WordsPairPostParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/words_pair'

        return callPostDispatchedApiSameName<WordsPairDTO, WurdStoreState, WordsPairUpdateDTO, WordsPairPostParams>(
            apiService, path, dispatch, WORDS_PAIR_POST, params.dto, params);

    }
}


export interface WordsPairPostStoreFields extends PostObjectStoreFieldsWithParams<WordsPairDTO,WordsPairPostParams> { }

export interface WordsPairPostAction extends WordsPairPostStoreFields {
    type: typeof WORDS_PAIR_POST
}