import axios from "axios";
import { bool } from "prop-types";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ThunkExtraArguments } from "src/redux/storeConfig/store";
import { ApiService, AxiosCallFunction } from "src/services/ApiService";



// ThunkDispatch<WurdStoreState, ThunkExtraArguments, Action<string>>
export interface ApiCallBaseData {
    uuid: string

}

/**
 * @typeParam  A  typ obiektu zwracany przez API
 * @typeParam  K  typ stanu store
 * @typeParam  R  typ obiektu zwracanego do store
 */
function callDispatchedApi<A, K, R = void, P = void>(apiService: ApiService,
    path: string,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    object_key_name: string,
    axios_call: AxiosCallFunction<A>,
    dto?: any,
    mapper?: ObjectMapper<A, R>,
    filterParamsObject?: P): Promise<void> {

    return apiService.callDispatchedApi<A>(axios_call,
        path,
        response => {
            let object_inner = {
                object: mapper ? mapper(response.data) : response.data
            } as any
            let dispatch_object = {} as any
            if (filterParamsObject !== undefined)
                object_inner.params = filterParamsObject
            dispatch_object[object_key_name] = object_inner

            dispatch({
                type: dispatch_type,
                object: dispatch_object
            })
        },
        undefined,
        dto,
        error => {
            let object_inner = {
                error: error
            } as any
            let dispatch_object = {} as any
            if (filterParamsObject !== undefined)
                object_inner.params = filterParamsObject
            dispatch_object[object_key_name] = object_inner

            dispatch({
                type: dispatch_type,
                object: dispatch_object
            })
        }
    )
}


// // T - zwracany rodzaj obiektu
// // K - typ stanu store
// export function callGetDispatchedApi<T, K>(apiService: ApiService,
//     path: string,
//     dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
//     dispatch_type: string,
//     object_key_name: string): Promise<void> {

//     return callDispatchedApi<T, K>(apiService, path, dispatch, dispatch_type, object_key_name, axios.get);
// }


/**
 * @typeParam  I  Rodzaj obiektu wejściowego
 * @typeParam  R  Rodzaj obiektu wyjściowego
 * @param object  Obiekt wejściowy
 * @returns Obiekt wyjściowy
 */
export type ObjectMapper<I, R> = {
    (object: I): R
};


/**
 * @typeParam  A  typ obiektu zwracany przez API
 * @typeParam  K  typ stanu store
 * @typeParam  R  typ obiektu zwracanego do store
 */
export function callGetDispatchedApi<A, K, R = void, P = void>(apiService: ApiService,
    path: string,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    object_key_name: string,
    mapper?: ObjectMapper<A, R>,
    filterParamsObject?: P): Promise<void> {

    return callDispatchedApi<A, K, R, P>(apiService, path, dispatch, dispatch_type, object_key_name, axios.get, undefined, mapper, filterParamsObject);
}



// T - zwracany rodzaj obiektu
// K - typ stanu store
// D - typ obiektu przekazywanego
export function callPutDispatchedApi<T, K, D>(apiService: ApiService,
    path: string,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    object_key_name: string,
    dto: D): Promise<void> {

    return callDispatchedApi<T, K>(apiService, path, dispatch, dispatch_type, object_key_name, axios.put, dto);
}

// T - zwracany rodzaj obiektu
// K - typ stanu store
// D - typ obiektu przekazywanego
export function callPostDispatchedApi<T, K, D>(apiService: ApiService,
    path: string,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    object_key_name: string,
    dto: D): Promise<void> {

    return callDispatchedApi<T, K>(apiService, path, dispatch, dispatch_type, object_key_name, axios.post, dto);
}

// SameName

/**
 * @typeParam  A  typ obiektu zwracany przez API
 * @typeParam  K  typ stanu store
 * @typeParam  R  typ obiektu zwracanego do store
 */
function dispatchToStore<A, K, R = void, P = void>(
    data: A,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    mapper?: ObjectMapper<A, R>,
    filterParamsObject?: P,
    toBeDeletedObjectId?: number,
    omitInnejObject?: boolean) {
    const object_key_name = dispatch_type.toLowerCase()


    let object_inner = {
        object: toBeDeletedObjectId ?? (mapper ? mapper(data) : data)
    } as any

    if (omitInnejObject !== undefined && omitInnejObject)
        object_inner = data;

    let dispatch_object = {} as any
    if (filterParamsObject !== undefined)
        object_inner.params = filterParamsObject
    dispatch_object[object_key_name] = object_inner

    dispatch({
        type: dispatch_type,
        object: dispatch_object
    })
}


/**
 * @typeParam  A  typ obiektu zwracany przez API
 * @typeParam  K  typ stanu store
 * @typeParam  R  typ obiektu zwracanego do store
 */
function callDispatchedApiSameName<A, K, R = void, P = void>(apiService: ApiService,
    path: string,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    axios_call: AxiosCallFunction<A>,
    dto?: any,
    mapper?: ObjectMapper<A, R>,
    filterParamsObject?: P,
    toBeDeletedObjectId?: number): Promise<void> {
    const object_key_name = dispatch_type.toLowerCase()

    return apiService.callDispatchedApi<A>(axios_call,
        path,
        response => {
            dispatchToStore<A, K, R, P>(response.data, dispatch, dispatch_type, mapper, filterParamsObject, toBeDeletedObjectId)
        },
        undefined,
        dto,
        error => {
            let object_inner = {
                error: error
            } as any
            let dispatch_object = {} as any
            if (filterParamsObject !== undefined)
                object_inner.params = filterParamsObject
            dispatch_object[object_key_name] = object_inner

            dispatch({
                type: dispatch_type,
                object: dispatch_object
            })
        }
    )
}

/**
 * @typeParam  A  typ obiektu zwracany przez API
 * @typeParam  K  typ stanu store
 * @typeParam  R  typ obiektu zwracanego do store
 */
export function callGetDispatchedApiSameName<A, K, R = void, P = void>(apiService: ApiService,
    path: string,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    mapper?: ObjectMapper<A, R>,
    filterParamsObject?: P): Promise<void> {

    return callDispatchedApiSameName<A, K, R, P>(apiService, path, dispatch, dispatch_type, axios.get, undefined, mapper, filterParamsObject);
}



// T - zwracany rodzaj obiektu
// K - typ stanu store
// D - typ obiektu przekazywanego
export function callPutDispatchedApiSameName<T, K, D, P = void>(apiService: ApiService,
    path: string,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    dto: D,
    paramsObject?: P): Promise<void> {

    return callDispatchedApiSameName<T, K, undefined, P>(apiService, path, dispatch, dispatch_type, axios.put, dto, undefined, paramsObject);
}

// T - zwracany rodzaj obiektu
// K - typ stanu store
// D - typ obiektu przekazywanego
export function callPatchDispatchedApiSameName<T, K, D, P = void>(apiService: ApiService,
    path: string,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    dto: D,
    paramsObject?: P): Promise<void> {

    if (dto === undefined || dto == null)
        dto = {} as any
    return callDispatchedApiSameName<T, K, undefined, P>(apiService, path, dispatch, dispatch_type, axios.patch, dto, undefined, paramsObject);
}

// T - zwracany rodzaj obiektu
// K - typ stanu store
// D - typ obiektu przekazywanego
export function callPostDispatchedApiSameName<T, K, D, P = void>(apiService: ApiService,
    path: string,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    dto: D,
    paramsObject?: P): Promise<void> {

    return callDispatchedApiSameName<T, K, undefined, P>(apiService, path, dispatch, dispatch_type, axios.post, dto, undefined, paramsObject);
}

/**
 * @typeParam  A  typ obiektu zwracany przez API
 * @typeParam  K  typ stanu store
 * @typeParam  R  typ obiektu zwracanego do store
 */
export function callDeleteDispatchedApiSameName<K, P = void>(apiService: ApiService,
    path: string,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string,
    toBeDeletedObjectId: number,
    paramsObject?: P): Promise<void> {

    return callDispatchedApiSameName<undefined, K, undefined, P>(apiService, path, dispatch, dispatch_type, axios.delete, undefined, undefined, paramsObject, toBeDeletedObjectId);
}



/**
 * @typeParam  A  typ obiektu zwracany przez API
 * @typeParam  K  typ stanu store
 */
export function callSetSameName<A, K>(
    data: A,
    dispatch: ThunkDispatch<K, ThunkExtraArguments, Action<string>>,
    dispatch_type: string) {

    dispatchToStore<A, K>(data, dispatch, dispatch_type, undefined, undefined, undefined, true);
}