import { PaginatedList, PaginatedListParams } from "src/interfaces/PaginatedList"
import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { callGetDispatchedApi, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk, FoodThunkDispatch } from "../../types"

/// PRODUCT_LIST_GET
export interface ProductsListGetDTO extends PaginatedList<ProductsDTO> {
}

export const PRODUCT_LIST_GET = 'PRODUCT_LIST_GET'

export interface ProductsListGetParams extends PaginatedListParams {
    textLikeFilter?: string

}

export const productListGet = (params: ProductsListGetParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/product'
        path = concatApiUrl(path, params.pageNumber, "pageNumber", 1)
        path = concatApiUrl(path, params.pageSize, "pageSize")
        path = concatApiUrl(path, params.textLikeFilter, "textLikeFilter")

        return callGetDispatchedApiSameName<ProductsListGetDTO, FoodStoreState, void, ProductsListGetParams>(
            apiService, path, dispatch, PRODUCT_LIST_GET, undefined, params);

    }
}

export const productListGetStoreBased = (dispatch: FoodThunkDispatch, store: FoodStoreState, pageNumberOverride?: number, pageSizeOverride?: number) => {
    dispatch(productListGet({
      textLikeFilter: store.product.product_list_get?.params?.textLikeFilter,
      pageNumber: pageNumberOverride ?? store.product.product_list_get?.params?.pageNumber,
      pageSize: pageSizeOverride ?? store.product.product_list_get?.params?.pageSize,
    }))
  }

export interface ProductsListGetStoreFields extends GetObjectStoreFieldsWithParams<ProductsListGetDTO, ProductsListGetParams> { }

export interface ProductsListGetAction extends ProductsListGetStoreFields {
    type: typeof PRODUCT_LIST_GET
}