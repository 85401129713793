import { PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO, ProductsValues } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk } from "../../types"
import { ProductUpdateableDTO } from "./productPost"

/// PRODUCT_PUT


export const PRODUCT_PUT = 'PRODUCT_PUT'

export interface ProductPutParams {
    id: number
    dto: ProductUpdateableDTO

}

export const productPut = (params: ProductPutParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/product/' + params.id

        return callPutDispatchedApiSameName<ProductsDTO, FoodStoreState, ProductUpdateableDTO>(
            apiService, path, dispatch, PRODUCT_PUT, params.dto);

    }
}

export interface ProductPutStoreFields extends PutObjectStoreFields<ProductsDTO> { }

export interface ProductPutAction extends ProductPutStoreFields {
    type: typeof PRODUCT_PUT
}