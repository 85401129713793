import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { WordDTO, WordsPairDTO } from "../../words_list/store/wordsListTypes"
import { WurdThunk, WurdStoreState } from "../../wurdStoreTypes"

export interface WordsConnectedDTO  {
    words: WordDTO[]
}

/// WORD_CONNECTED_GET

export const WORD_CONNECTED_GET = 'WORD_CONNECTED_GET'

export interface WordConnectedGetParams extends ApiCallBaseData {
    id: number
    languageId: number
    includeWordAttributes: boolean

}

export const wordConnectedGet = (params: WordConnectedGetParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/word/' + params.id + '/connected'
        path = concatApiUrl(path, params.languageId, "languageId")
        path = concatApiUrl(path, params.includeWordAttributes, "includeWordAttributes")

        return callGetDispatchedApiSameName<WordsConnectedDTO, WurdStoreState, void, WordConnectedGetParams>(
            apiService, path, dispatch, WORD_CONNECTED_GET, undefined, params);

    }
}

export interface WordConnectedGetStoreFields extends GetObjectStoreFieldsWithParams<WordsConnectedDTO, WordConnectedGetParams> { }

export interface WordConnectedGetAction extends WordConnectedGetStoreFields {
    type: typeof WORD_CONNECTED_GET
}