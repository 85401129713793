import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { UserStoreState, UserThunk } from "../types"

export interface GroupDTO {
    id: number
    name: string
}

export interface UserGroupsDTO {
    groups: GroupDTO[]
}

/// USER_GROUPS_GET

export const USER_GROUPS_GET = 'USER_GROUPS_GET'

export interface UserGroupsGetParams extends ApiCallBaseData {

}

export const userGroupsGet = (params: UserGroupsGetParams): UserThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/user/groups'

        return callGetDispatchedApiSameName<UserGroupsDTO, UserStoreState, void, UserGroupsGetParams>(
            apiService, path, dispatch, USER_GROUPS_GET, undefined, params);

    }
}

export interface UserGroupsStoreFields extends GetObjectStoreFieldsWithParams<UserGroupsDTO, UserGroupsGetParams> { }

export interface UserGroupsAction extends UserGroupsStoreFields {
    type: typeof USER_GROUPS_GET
}