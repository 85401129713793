import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ThunkExtraArguments } from "src/redux/storeConfig/store";
import { SetUserRolesAction, UserRolesStoreFields } from "./store/setUserRoles";
import { UserGroupsAction, UserGroupsStoreFields } from "./store/userGroupsGet";
import { GroupAction, GroupStoreFields } from "./store/groupGet";
import { UserGoogleCodePutAction, UserGoogleCodePutStoreFields } from "./store/userGoogleCodePut";



export type UserThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    UserStoreState,
    ThunkExtraArguments,
    Action<string>
>

export interface UserStoreState {
  user_roles?: UserRolesStoreFields
  user_groups_get?: UserGroupsStoreFields
  user_google_code_put?: UserGoogleCodePutStoreFields
  group_get?: GroupStoreFields
}

export type UserActionTypes = SetUserRolesAction | UserGroupsAction | GroupAction | ClearUsersStorePartialAction | UserGoogleCodePutAction

export type UserThunkDispatch = ThunkDispatch<UserStoreState, ThunkExtraArguments, Action>;

export const CLEAR_USERS_STORE_PARTIAL = 'CLEAR_USERS_STORE_PARTIAL'

export interface ClearUsersStorePartialAction {
    type: typeof CLEAR_USERS_STORE_PARTIAL
}