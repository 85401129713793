import { PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO, ProductsValues } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk } from "../../types"
import { preparePostPutBody } from "./actions"
import { DishRecipeDTO, DishUploadDTO } from "./types"

/// DISH_PUT

export interface DishRichTextUpdateDTO extends DishUploadDTO {
    // recipeRichText: string
}

export const DISH_PUT = 'DISH_PUT'

export interface DishPutParams {
    id: number
    dto: DishRichTextUpdateDTO

}

export const dishPut = (params: DishPutParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/dishes/' + params.id;
        
        let formData = preparePostPutBody(params.dto)
        return callPutDispatchedApiSameName<ProductsDTO, FoodStoreState, FormData>(
            apiService, path, dispatch, DISH_PUT, formData);

    }
}

export interface DishPutStoreFields extends PutObjectStoreFields<DishRecipeDTO> { }

export interface DishPutAction extends DishPutStoreFields {
    type: typeof DISH_PUT
}