import { RequirementCloseAction, RequirementCloseStoreFields } from "./requirementClose"
import { RequirementDeleteAction, RequirementDeleteStoreFields } from "./requirementDelete"
import { RequirementGetAction, RequirementGetStoreFields } from "./requirementGet"





/// CLEAR_PRODUCT_REQUIREMENT

export const CLEAR_PRODUCT_REQUIREMENT = 'CLEAR_PRODUCT_REQUIREMENT'

export interface ClearProductRequirementAction {
    type: typeof CLEAR_PRODUCT_REQUIREMENT
}

// STORE

export interface FoodDishStoreState {
    requirement_close?: RequirementCloseStoreFields
    requirement_delete?: RequirementDeleteStoreFields
    requirement_get?: RequirementGetStoreFields
  
}


export type ProductRequirementsActionTypes = ClearProductRequirementAction | RequirementCloseAction | RequirementDeleteAction | RequirementGetAction