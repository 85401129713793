import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk } from "src/views/food/types"


export interface DishBasicsDTO {
    id: number
    name: string
}

export interface DishesBasicsListDTO {
    dishes: DishBasicsDTO[]
}


export const PRODUCT_DISH_LIST_GET = 'PRODUCT_DISH_LIST_GET'

export interface ProductDishListGetParams extends ApiCallBaseData {
    productId: number

}

export const productDishListGet = (params: ProductDishListGetParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        console.log("called")
        var path: string = '/food/product/' + params.productId + "/dish"

        return callGetDispatchedApiSameName<DishesBasicsListDTO, FoodStoreState, void, ProductDishListGetParams>(
            apiService, path, dispatch, PRODUCT_DISH_LIST_GET, undefined, params);

    }
}

export interface ProductDishListGetStoreFields extends GetObjectStoreFieldsWithParams<DishesBasicsListDTO, ProductDishListGetParams> { }

export interface ProductDishListGetAction extends ProductDishListGetStoreFields {
    type: typeof PRODUCT_DISH_LIST_GET
}