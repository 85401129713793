import { PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { BankingStoreState, BankingThunk } from "../../types"



export const UTILITIES_ETL_POST = 'UTILITIES_ETL_POST'

export interface UtilitiesEtlPostParams {
    etlSettingId: number

}

export const utilitiesEtlPost = (params: UtilitiesEtlPostParams): BankingThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/utilities/etl' 
        path = concatApiUrl(path, params.etlSettingId, "etlSettingId")

        return callPostDispatchedApiSameName<string, BankingStoreState, any>(
            apiService, path, dispatch, UTILITIES_ETL_POST, {});

    }
}

export interface UtilitiesEtlPostStoreFields extends PostObjectStoreFields<string> { }

export interface UtilitiesEtlPostAction extends UtilitiesEtlPostStoreFields {
    type: typeof UTILITIES_ETL_POST
}