import { BudgetForecastGetAction, BudgetForecastGetStoreFields } from "./budgetForecastGet"
import { BudgetPlansGetAction, BudgetPlansGetStoreFields } from "./budgetPlansGet"
import { BudgetPlanPutAction, BudgetPlanPutStoreFields } from "./budgetPlanPut"
import { BudgetPlansPostAction, BudgetPlansPostStoreFields } from "./budgetPlansPost"
import { BudgetPlanDeleteAction, BudgetPlanDeleteStoreFields } from "./budgetPlanDelete"
import { BudgetPlansMonthGetAction, BudgetPlansMonthGetStoreFields } from "./budgetPlansMonthGet"



// SAVE_BUDGET_PAGE_DATA

export interface BudgetPageStoreFields {
    isAveragePerCategoryOn?: boolean // wyświetlanie podsumowań per miesiąc
}
export const SAVE_BUDGET_PAGE_DATA = 'SAVE_BUDGET_PAGE_DATA'

export interface SaveBudgetPageDataAction {
    type: typeof SAVE_BUDGET_PAGE_DATA
    object: BudgetPageStoreFields
}

export const CLEAR_BUDGET = 'CLEAR_BUDGET'

export interface ClearBudgetAction {
    type: typeof CLEAR_BUDGET
}


/// STORE

export interface BudgetMonthlyStoreState {
    budget_forecast_get?: BudgetForecastGetStoreFields
    budget_plans_get?: BudgetPlansGetStoreFields
    budget_plans_post?: BudgetPlansPostStoreFields
    budget_plan_put?: BudgetPlanPutStoreFields
    budget_plan_delete?: BudgetPlanDeleteStoreFields
    budget_plans_month_get?: BudgetPlansMonthGetStoreFields

    page_data?: BudgetPageStoreFields
}

export const budgetMonthlyInitialState: BudgetMonthlyStoreState = {
}

export type BudgetMonthlyActionTypes = BudgetPlansGetAction | BudgetPlansPostAction | BudgetForecastGetAction | BudgetPlansMonthGetAction
     | BudgetPlanDeleteAction | BudgetPlanPutAction | SaveBudgetPageDataAction | ClearBudgetAction


