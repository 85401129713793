import { GetObjectStoreFields, PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { RequirementPeriodCoreDTO } from "../../requirements/store/types"




/// GET_PRODUCT_REQUIREMENTS


export interface RequirementPeriodValueDTO extends RequirementPeriodCoreDTO {
    requirementPeriodValueId?: number
}

export interface ProductRequirementsDTO {
    requirements: RequirementPeriodValueDTO[]
}


export const GET_PRODUCT_REQUIREMENTS = 'GET_PRODUCT_REQUIREMENTS'

export interface GetProductRequirementsFields extends GetObjectStoreFields<ProductRequirementsDTO> { }

export interface GetProductRequirementsAction extends GetProductRequirementsFields {
    type: typeof GET_PRODUCT_REQUIREMENTS
}


/// POST_PRODUCT_REQUIREMENT

export interface RequirementAddDTO {
    requirementTypeId: number
    startDate: string
    endDate?: string
    itemsCount?: number
    itemsSum?: number
    findText?: string
    name: string
    dateIdentifier: number
    daysPostpone?: number
    financialProductId: number
}

export interface RequirementDTO extends RequirementAddDTO {
    id: number
    statusComputing?: string
    // periodDaysLength?: number
    // periodsCount?: number
}


// RequirementAddDTO -> RequirementDTO

export const POST_PRODUCT_REQUIREMENT = 'POST_PRODUCT_REQUIREMENT'

interface PostProductRequirementStoreFields extends PostObjectStoreFields<RequirementDTO> {
}

export interface PostProductRequirementAction extends PostProductRequirementStoreFields {
    type: typeof POST_PRODUCT_REQUIREMENT
}

/// CLEAR_PRODUCTS_PRODUCT_REQ

export const CLEAR_PRODUCTS_PRODUCT_REQ = 'CLEAR_PRODUCTS_PRODUCT_REQ'

export interface ClearProductsProductRequirementsAction {
    type: typeof CLEAR_PRODUCTS_PRODUCT_REQ
}

/// STORE

export interface ProductRequirementsStoreState {
    get?: GetProductRequirementsFields
    requirement_post?: PostProductRequirementStoreFields
}


export const productRequirementsInitialState: ProductRequirementsStoreState = {
}

export type ProductRequirementsActionTypes = GetProductRequirementsAction | PostProductRequirementAction | ClearProductsProductRequirementsAction;
