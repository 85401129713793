import { PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO } from "src/views/food/dicts/store/types"
import { FoodThunk, FoodStoreState } from "src/views/food/types"
import { ProductUnitConversionDTO } from "./productConversionListGet"


/// PRODUCT_CONVERSION_POST

export interface ProductUnitConversionUpdateableDTO {
    productId: number
    secondUnitId: number
    conversion: number
}

export const PRODUCT_CONVERSION_POST = 'PRODUCT_CONVERSION_POST'

export interface ProductConversionPostParams {
    dto: ProductUnitConversionUpdateableDTO

}

export const productConversionPost = (params: ProductConversionPostParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/product/' + params.dto.productId + "/conversion" 

        return callPostDispatchedApiSameName<ProductUnitConversionDTO, FoodStoreState, ProductUnitConversionUpdateableDTO>(
            apiService, path, dispatch, PRODUCT_CONVERSION_POST, params.dto);

    }
}

export interface ProductConversionPostStoreFields extends PostObjectStoreFields<ProductUnitConversionDTO> { }

export interface ProductConversionPostAction extends ProductConversionPostStoreFields {
    type: typeof PRODUCT_CONVERSION_POST
}