import { DeleteObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callDeleteDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { WurdStoreState, WurdThunk } from "../../wurdStoreTypes"

/// FICHES_LIST_DELETE


export const FICHES_LIST_DELETE = 'FICHES_LIST_DELETE'

export interface FichesListDeleteParams extends ApiCallBaseData {
    id: number
}

export const fichesListDelete = (params: FichesListDeleteParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/fiches_list/' + params.id

        return callDeleteDispatchedApiSameName<WurdStoreState>(
            apiService, path, dispatch, FICHES_LIST_DELETE, params.id);

    }
}

export interface FichesListDeleteStoreFields extends DeleteObjectStoreFields { }

export interface FichesListDeleteAction extends FichesListDeleteStoreFields {
    type: typeof FICHES_LIST_DELETE
}