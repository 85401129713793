import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { instanceOf } from 'prop-types';
import { LoggingAreaEnum, logInline } from 'src/helpers/logger';
import { Constants } from '../helpers/Constants';
import { AuthService } from './AuthService';

export interface Action<T> {
  (item: T): void;
}

export interface Func<T, TResult> {
  (item: T): TResult;
}

export interface Func2<T, R, TResult> {
  (item: T, item2: R): TResult;
}

export interface FuncRO<T, TResult, R = null > {
  (item: T, item2?: R): TResult;
}

export interface ApiError {
  message: string,
  status?: number,
  traceId?: string
}

interface ApiValidationError {
  subCode: number,
  trace_id: string,
  messages: { [field: string]: string }
}

export type AxiosCallFunction<S> = {
  (url: string,
    data?: any,
    config?: AxiosRequestConfig): Promise<AxiosResponse<S>>
};

export class ApiService {
  private authService: AuthService;

  constructor() {
    this.authService = new AuthService();
  }

  public callApi(): Promise<any> {
    return this.authService.getUser().then(user => {
      if (user && user.access_token) {
        return this._callApi(user.access_token).catch(error => {
          if (error.response.status === 401) {
            return this.authService.renewToken().then(renewedUser => {
              return this._callApi(renewedUser.access_token);
            });
          }
          throw error;
        });
      } else if (user) {
        return this.authService.renewToken().then(renewedUser => {
          return this._callApi(renewedUser.access_token);
        });
      } else {
        throw new Error('user is not logged in');
      }
    });
  }

  private axiosThenErrorGeneric = (error: ApiError) => {
    console.log(error)
    throw new Error(error.message)
  }



  public callDispatchedApi<S>(
    axiosCall: AxiosCallFunction<S>,
    url: string,
    axiosThen: (error: AxiosResponse<S>) => void,
    config?: AxiosRequestConfig,
    data?: any,
    axiosThenError: (error: ApiError) => void = this.axiosThenErrorGeneric): Promise<void> {
    return this.authService.getUser().then(user => {
      if (user && user.access_token) {
        return this._callGenericApi<S>(axiosCall, axiosThen, url, user.access_token, config, data).catch((error: AxiosError<ApiValidationError>) => {

          if (error.response?.status === 401) {
            logInline(LoggingAreaEnum.AUTH_LOG, "401 na wywołaniu", "Próba odświeżenia tokena")

            return this.authService.renewToken().then(renewedUser => {
              logInline(LoggingAreaEnum.AUTH_LOG, "401 na wywołaniu", "Ponowne wywołanie API z odświeżonym użytkownikiem")

              return this._callGenericApi(axiosCall, axiosThen, url, renewedUser.access_token, config, data);
            }).catch((error: Error) => {
              logInline(LoggingAreaEnum.AUTH_LOG, "401 na wywołaniu", "Błąd podczas próby odświeżenia tokena")

              if (error.message === "login_required")
                this.authService.login()
              else
                throw error;
            });
          }

          if (error instanceof Error) {
            axiosThenError({ message: error.message })
          }


          if (error.response?.data.messages) {

            let details = Object.entries(error.response.data.messages).map(val => val[1]).join('\n')
            axiosThenError({ message: details, status: error.response.status, traceId: error.response.data.trace_id })
          } else if (error.message && error.response) {
            axiosThenError({ message: error.message, status: error.response.status, traceId: error.response.data.trace_id })

          } else
            throw error;

        });
      } else if (user) {
        return this.authService.renewToken().then(renewedUser => {
          return this._callGenericApi(axiosCall, axiosThen, url, renewedUser.access_token, config, data);
        });
      } else {
        throw new Error('user is not logged in');
      }
    });

    // return axiosCall(url, config).then(axiosThen)

  }

  private _callGenericApi<S>(
    axiosCall: AxiosCallFunction<S>,
    axiosThen: (response: AxiosResponse<S>) => void,
    url: string,
    token: string,
    config?: AxiosRequestConfig,
    data?: any): Promise<void> {
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    };
    if (data)
      return axiosCall(Constants.apiRoot + url, data, { ...config, headers: { ...headers } }).then(axiosThen)
    else
      return axiosCall(Constants.apiRoot + url, { ...config, headers: { ...headers } }).then(axiosThen)
  }


  private _callApi(token: string) {
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    };

    return axios.get(Constants.apiRoot + 'test', { headers });
  }
}
