


/// CLEAR_FOOD_TAG

import { FoodTagPostAction, FoodTagPostStoreFields } from "./tagPost"

export const CLEAR_FOOD_TAG = 'CLEAR_FOOD_TAG'

export interface ClearFoodTagAction {
    type: typeof CLEAR_FOOD_TAG
}

// STORE

export interface FoodTagStoreState {
    tag_post?: FoodTagPostStoreFields 
}

export const foodTagInitialState: FoodTagStoreState = {}


export type FoodTagActionTypes = FoodTagPostAction | ClearFoodTagAction 