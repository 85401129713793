import { DeleteObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callDeleteDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsStoreState, ProductsThunk } from "../../types"

/// REQUIREMENT_DELETE


export const REQUIREMENT_DELETE = 'REQUIREMENT_DELETE'

export interface RequirementDeleteParams {
    id: number

}

export const requirementDelete = (params: RequirementDeleteParams): ProductsThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/requirements/' + params.id

        return callDeleteDispatchedApiSameName<ProductsStoreState>(
            apiService, path, dispatch, REQUIREMENT_DELETE, params.id);

    }
}

export interface RequirementDeleteStoreFields extends DeleteObjectStoreFields { }

export interface RequirementDeleteAction extends RequirementDeleteStoreFields {
    type: typeof REQUIREMENT_DELETE
}