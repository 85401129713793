import { PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { RequirementDTO } from "../../product/store/ProductRequirementsTypes"
import { ProductsStoreState, ProductsThunk } from "../../types"


/// REQUIREMENT_PUT

export const REQUIREMENT_CLOSE = 'REQUIREMENT_CLOSE'

export interface RequirementCloseParams {
    id: number

}

export const requirementClose = (params: RequirementCloseParams): ProductsThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/requirements/' + params.id + '/close';
        
        return callPutDispatchedApiSameName<RequirementDTO, ProductsStoreState, {}>(
            apiService, path, dispatch, REQUIREMENT_CLOSE, {});

    }
}

export interface RequirementCloseStoreFields extends PutObjectStoreFields<RequirementDTO> { }

export interface RequirementCloseAction extends RequirementCloseStoreFields {
    type: typeof REQUIREMENT_CLOSE
}