import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { WordDTO, WordsPairDTO } from "../../words_list/store/wordsListTypes"
import { WurdThunk, WurdStoreState } from "../../wurdStoreTypes"

export interface WordWithPairsDTO extends WordDTO {
    pairsWordIsAsLearn: WordsPairDTO[]
    pairsWordIsAsOrigin: WordsPairDTO[]
}

/// WORD_GET

export const WORD_GET = 'WORD_GET'

export interface WordGetParams extends ApiCallBaseData {
    id: number

}

export const wordGet = (params: WordGetParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/word/' + params.id

        return callGetDispatchedApiSameName<WordWithPairsDTO, WurdStoreState, void, WordGetParams>(
            apiService, path, dispatch, WORD_GET, undefined, params);

    }
}

export interface WordGetStoreFields extends GetObjectStoreFieldsWithParams<WordWithPairsDTO, WordGetParams> { }

export interface WordGetAction extends WordGetStoreFields {
    type: typeof WORD_GET
}