import { PostObjectStoreFields, PostObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { MealsPlanEntryDTO } from "src/views/food/meals_plan/store/mealsPlanEntryPost"
import { WordAttributeUpdateDTO, WordDTO } from "../../words_list/store/wordsListTypes"
import { WurdThunk, WurdStoreState } from "../../wurdStoreTypes"

export interface WordDuplicateConsolidateDTO {
    loosersIds: number[]
    winnerId: number
}

/// WORD_DUPLICATE_CONSOLIDATE_POST

export const WORD_DUPLICATE_CONSOLIDATE_POST = 'WORD_DUPLICATE_CONSOLIDATE_POST'

export interface WordDuplicateConsolidatePostParams extends ApiCallBaseData  {
    dto: WordDuplicateConsolidateDTO
}

export const wordDuplicateConsolidatePost = (params: WordDuplicateConsolidatePostParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/word/duplicate/consolidate'

        return callPostDispatchedApiSameName<WordDuplicateConsolidateDTO, WurdStoreState, WordDuplicateConsolidateDTO, WordDuplicateConsolidatePostParams>(
            apiService, path, dispatch, WORD_DUPLICATE_CONSOLIDATE_POST, params.dto, params);

    }
}


export interface WordDuplicateConsolidatePostStoreFields extends PostObjectStoreFieldsWithParams<WordDuplicateConsolidateDTO,WordDuplicateConsolidatePostParams> { }

export interface WordDuplicateConsolidatePostAction extends WordDuplicateConsolidatePostStoreFields {
    type: typeof WORD_DUPLICATE_CONSOLIDATE_POST
}