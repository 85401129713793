import { DeleteObjectStoreFields, GetObjectStoreFields, PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"


/// GET_SPOTIFY_TABLE


export interface SpotifyUserStatsDTO {
    login: string
    userName: string
    surname: string
    email: string
    contact?: string
    bankingTransactionCategoryId: number
    subscriptionType: number
    startDate: string
    daysLeft: number
    paidDate: string
}

export interface SpotifyUsersViewDTO {
    users: SpotifyUserStatsDTO[]
}


export const GET_SPOTIFY_TABLE = 'GET_SPOTIFY_TABLE'

export interface GetSpotifyTableStoreFields extends GetObjectStoreFields<SpotifyUsersViewDTO> { }

export interface GetSpotifyTableAction extends GetSpotifyTableStoreFields {
    type: typeof GET_SPOTIFY_TABLE
}

/// POST_SPOTIFY_EMAILS

export interface SpotifyEmailSendUserDTO {
    login: string
}

export interface SpotifyEmailSendDTO {
    users: SpotifyEmailSendUserDTO[]
}


export const POST_SPOTIFY_EMAILS = 'POST_SPOTIFY_EMAILS'

export interface PostSpotifyEmailsStoreFields extends GetObjectStoreFields<SpotifyEmailSendDTO> { }

export interface PostSpotifyEmailsAction extends PostSpotifyEmailsStoreFields {
    type: typeof POST_SPOTIFY_EMAILS
}

// CLEAR_SPOTIFY

export const CLEAR_SPOTIFY = 'CLEAR_SPOTIFY'

export interface ClearSpotifyAction  {
    type: typeof CLEAR_SPOTIFY
}


/// STORE

export interface SpotifyDashboardStoreState {
    table_get?: GetSpotifyTableStoreFields
    emails_post?: PostSpotifyEmailsStoreFields
}

export const spotifyDashboardInitialState: SpotifyDashboardStoreState = {
}

export type SpotifyDashboardActionTypes = GetSpotifyTableAction | PostSpotifyEmailsAction | ClearSpotifyAction

