import { ApiError } from "src/services/ApiService"
import { BankingTransactionsGetAction, BankingTransactionsGetStoreFields } from "./bankingTransactionsGet";
import { BankingStoreState, BankingThunk } from "../../types";
import { clearPartialSameNameGeneric } from "src/redux/actions/clearPartialGeneric";
import { BankingTransactionSplitPutAction, BankingTransactionSplitPutStoreFields } from "./bankingTransactionSplitPut";
import { BankingTransactionPutAction, BankingTransactionPutStoreFields } from "./bankingTransactionPut";



export enum BankingTransactionKindEnum {
    NiePrzetworzono = 0,
    NieZidentyfikowano = 1,
    PrzelewPrzychodzacy = 2,
    PrzelewPrzychodzacyOdsetki = 3,
    PrzelewWychodzacy = 4,
    PrzelewWychodzacyPodatekOdOdsetek = 5,
    TransakcjaKarta = 6,
    TransakcjaKartaZwrot = 7,
    PrzelewPrzychodzacyBLIK = 8,
    PrzelewWychodzacyBLIK = 9,
    PlatnoscBLIK = 10
}

export const BankingTransactionKindKeysMap: Map<BankingTransactionKindEnum, string> =
    new Map([
        [BankingTransactionKindEnum.NiePrzetworzono, "Nie przetworzono"],
        [BankingTransactionKindEnum.NieZidentyfikowano, "Nie zidentyfikowano"],
        [BankingTransactionKindEnum.PrzelewPrzychodzacy, "Przelew przychodzący"],
        [BankingTransactionKindEnum.PrzelewPrzychodzacyOdsetki, "Odsetki"],
        [BankingTransactionKindEnum.PrzelewWychodzacy, "Przelew wychodzący"],
        [BankingTransactionKindEnum.PrzelewWychodzacyPodatekOdOdsetek, "Podatek od odsetek"],
        [BankingTransactionKindEnum.TransakcjaKarta, "Transakcja kartą"],
        [BankingTransactionKindEnum.TransakcjaKartaZwrot, "Zwrot transakcji kartą"],
        [BankingTransactionKindEnum.PrzelewPrzychodzacyBLIK, "Przelew przychodzący BLIK"],
        [BankingTransactionKindEnum.PrzelewWychodzacyBLIK, "Przelew wychodzący BLIK"],
        [BankingTransactionKindEnum.PlatnoscBLIK, "Płatność BLIK"]
    ]);

/// CLEAR_ERROR


interface ClearErrorActionStoreFields {
    error?: ApiError
}

export const CLEAR_ERROR = 'CLEAR_ERROR'

export interface ClearErrorAction extends ClearErrorActionStoreFields {
    type: typeof CLEAR_ERROR
}


/// GET_BANKING_ACCOUNTS

export interface BankingAccountDTO {
    id: number
    accountName: string
    bankName: string
    isDefaultWallet: boolean
    isSavings: boolean
    isArchived: boolean
    isCredit: boolean
    overrideDisplayName?: string
}

export interface BankingAccountsResponseDTO {
    accounts: BankingAccountDTO[]
}

interface GetBankingAccountsActionStoreFields {
    userAccountList?: BankingAccountsResponseDTO
}

export const GET_BANKING_ACCOUNTS = 'GET_BANKING_ACCOUNTS'

export interface GetBankingAccountsAction extends GetBankingAccountsActionStoreFields {
    type: typeof GET_BANKING_ACCOUNTS
}


/// CLEAR_BANKING_TRANSACTIONS

export const CLEAR_BANKING_TRANSACTIONS = 'CLEAR_BANKING_TRANSACTIONS'

export interface ClearFoodProductAction {
    type: typeof CLEAR_BANKING_TRANSACTIONS
}

export const clearBankingTransactionsPartial = (part: string): BankingThunk => {
    return (dispatch) => {
        clearPartialSameNameGeneric<BankingStoreState>(part, CLEAR_BANKING_TRANSACTIONS, dispatch);
    }
}

/// STORE

export interface BankingTransactionsStoreState extends
    GetBankingAccountsActionStoreFields {
    banking_transactions_get?: BankingTransactionsGetStoreFields
    banking_transaction_split_put?: BankingTransactionSplitPutStoreFields
    banking_transaction_put?: BankingTransactionPutStoreFields

}

export const bankingTransactionsInitialState: BankingTransactionsStoreState = {
}

export type BankingTransactionsActionTypes = BankingTransactionsGetAction | BankingTransactionPutAction |
    ClearErrorAction | GetBankingAccountsAction | ClearFoodProductAction | BankingTransactionSplitPutAction


