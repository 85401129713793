import { PaginatedList, PaginatedListParams } from "src/interfaces/PaginatedList"
import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { WordDTO } from "../../words_list/store/wordsListTypes"
import { WurdThunk, WurdStoreState, WurdThunkDispatch } from "../../wurdStoreTypes"
import { v4 as uuidv4 } from 'uuid';

export interface WordsPaginatedDTO extends PaginatedList<WordDTO> {
}

/// WORDS_GET

export const WORDS_GET = 'WORDS_GET'

export interface GetWurdWordsParams extends PaginatedListParams, ApiCallBaseData {
    textLikeFilter?: string
    languageId?: number
    partOfSpeechTypeId?: number
    isWrong?: boolean
    includeWordAttributes?: boolean
}

export const wordsGet = (params: GetWurdWordsParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/word'
        path = concatApiUrl(path, params.pageNumber, "pageNumber", 1)
        path = concatApiUrl(path, params.pageSize, "pageSize")
        path = concatApiUrl(path, params.textLikeFilter, "textLikeFilter")
        path = concatApiUrl(path, params.isWrong, "isWrong")
        path = concatApiUrl(path, params.languageId, "languageId")
        path = concatApiUrl(path, params.partOfSpeechTypeId, "partOfSpeechTypeId")
        path = concatApiUrl(path, params.includeWordAttributes, "includeWordAttributes")

        return callGetDispatchedApiSameName<WordsPaginatedDTO, WurdStoreState, void, GetWurdWordsParams>(
            apiService, path, dispatch, WORDS_GET, undefined, params);

    }
}

export const wordsGetStoreBased = (dispatch: WurdThunkDispatch, store: WurdStoreState, includeWordAttributes: boolean,
    pageNumberOverride?: number, pageSizeOverride?: number, uuid?: string) => {
    dispatch(wordsGet({
        isWrong: store.words.words_get?.params?.isWrong,
        languageId: store.words.words_get?.params?.languageId,
        partOfSpeechTypeId: store.words.words_get?.params?.partOfSpeechTypeId,
        textLikeFilter: store.words.words_get?.params?.textLikeFilter,
        pageNumber: pageNumberOverride ?? store.words.words_get?.params?.pageNumber,
        pageSize: pageSizeOverride ?? store.words.words_get?.params?.pageSize,
        includeWordAttributes: includeWordAttributes,
        uuid: uuid ?? uuidv4()
    }))
}



export interface WordsGetStoreFields extends GetObjectStoreFieldsWithParams<WordsPaginatedDTO, GetWurdWordsParams> {

}

export interface WordsGetAction extends WordsGetStoreFields {
    type: typeof WORDS_GET
}