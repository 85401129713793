import { PaginatedList } from "src/interfaces/PaginatedList"
import { GetObjectStoreFields, PostObjectStoreFields, PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiError } from "src/services/ApiService"
import { WordCreateDTO } from "../../words/store/wordPost"
import { WordsPairUpdateDTO } from "../../words/store/wordsPairPost"
import { GetWurdFichesListDashboardGeneralAction, GetWurdFichesListDashboardGeneralStoreFields } from "./fichesListDashboardGeneralGet"
import { FichesListDeleteAction, FichesListDeleteStoreFields } from "./fichesListDelete"


/// GET_WURD_WORDS_LISTS

export interface WordsListUpdateDTO {
    languageLearnId: number
    languageOriginId: number
    name: string
    isPublic: boolean
}

export interface WordsListDTO extends WordsListUpdateDTO {
    id: number
    created: string
}

export interface WordsListsPaginatedDTO extends PaginatedList<WordsListStatsDTO> {
}

export const GET_WURD_WORDS_LISTS = 'GET_WURD_WORDS_LISTS'

interface GetWurdWordsListsStoreFields extends GetObjectStoreFields<WordsListsPaginatedDTO> {

}

export interface GetWurdWordsListsAction extends GetWurdWordsListsStoreFields {
    type: typeof GET_WURD_WORDS_LISTS
}

/// GET_WURD_WORDS_LIST

export interface FichesListPutDTO {
    box?: number
    status?: number
    name?: string
}

export interface FichesListUpdateDTO {
    box: number
    status: number
    name: string
}

export interface FichesListCreateDTO extends FichesListUpdateDTO {
    wordsListId: number
    checkText: boolean
    direction: boolean
}

export interface FichesListDTO extends FichesListCreateDTO {
    id: number
    updated: string
    totalLearnTimeInSeconds: number
    progress: number
}



export interface WordsListStatsDTO extends WordsListDTO {
    fichesLists: FichesListDTO[]
    wordsPairs: WordsPairDTO[]
    averageProgress: number
    pairsCount: number
}

export const WORDS_LIST_GET = 'WORDS_LIST_GET'

interface GetWurdWordsListStoreFields extends GetObjectStoreFields<WordsListStatsDTO> {
}

export interface GetWurdWordsListAction extends GetWurdWordsListStoreFields {
    type: typeof WORDS_LIST_GET
}

/// POST_WURD_WORDS_LIST

// WordsListUpdateDTO -> WordsListDTO

export const WORDS_LIST_POST = 'WORDS_LIST_POST'

export interface PostWordsListStoreFields extends PostObjectStoreFields<WordsListDTO> {
}

export interface PostWordsListAction extends PostWordsListStoreFields {
    type: typeof WORDS_LIST_POST
}

/// GET_WURD_FICHES_LIST

export interface WordAttributeUpdateDTO {
    partOfSpeechAttributeId: number
    value: string
}

export interface WordAttributeDTO extends WordAttributeUpdateDTO {
    id: number
    wordId: number
}



export interface WordDTO extends WordCreateDTO {
    id: number
    wordAtrributes: WordAttributeDTO[]
}



export interface WordsPairDTO extends WordsPairUpdateDTO {
    id: number
    wordLanguageLearn: WordDTO
    wordLanguageOrigin: WordDTO
    wordsList: WordsListDTO
}

export interface FicheUpdateDTO {
    box?: number
    w1?: number
    w2?: number
    w3?: number
    w4?: number
    w5?: number
}

export interface FicheDTO {
    id: number
    wordsPair: WordsPairDTO
    box: number
    w1: number
    w2: number
    w3: number
    w4: number
    w5: number
}

export interface FichesListDetailedDTO extends FichesListDTO {
    fiches: FicheDTO[]
}

export const GET_WURD_FICHES_LIST = 'GET_WURD_FICHES_LIST'

interface GetWurdFichesListStoreFields extends GetObjectStoreFields<FichesListDetailedDTO> {
}

export interface GetWurdFichesListAction extends GetWurdFichesListStoreFields {
    type: typeof GET_WURD_FICHES_LIST
}

/// PUT_WURD_FICHES_LIST

// FichesListUpdateDTO -> FichesListDTO

export const PUT_WURD_FICHES_LIST = 'PUT_WURD_FICHES_LIST'

interface PutWurdFichesListStoreFields extends PutObjectStoreFields<FichesListDTO> {
}

export interface PutWurdFichesListListsAction extends PutWurdFichesListStoreFields {
    type: typeof PUT_WURD_FICHES_LIST
}

/// POST_WURD_FICHES_LIST

// FichesListCreateDTO -> FichesListDTO

export const FICHES_LIST_POST = 'FICHES_LIST_POST'

interface PostWurdFichesListStoreFields extends PostObjectStoreFields<FichesListDTO> {
}

export interface PostWurdFichesListListsAction extends PostWurdFichesListStoreFields {
    type: typeof FICHES_LIST_POST
}

/// PUT_WURD_FICHE

// FicheUpdateDTO -> FicheDTO

export const PUT_WURD_FICHE = 'PUT_WURD_FICHE'

interface PutWurdFicheStoreFields extends PutObjectStoreFields<FicheDTO> {
}

export interface PutWurdFicheListsAction extends PutWurdFicheStoreFields {
    type: typeof PUT_WURD_FICHE
}

// CLEAR_WURD_WORDS_LIST

export const CLEAR_WURD_WORDS_LIST = 'CLEAR_WURD_WORDS_LIST'

export interface ClearWurdWordsListAction {
    type: typeof CLEAR_WURD_WORDS_LIST
}

// GET_WURD_FICHES_LIST_RECENT





/// STORE

export interface WurdWordsListStoreState {
    words_lists_get?: GetWurdWordsListsStoreFields
    words_list_get?: GetWurdWordsListStoreFields
    words_list_post?: PostWordsListStoreFields
    fiches_list_get?: GetWurdFichesListStoreFields
    fiches_list_put?: PutWurdFichesListStoreFields
    fiches_list_post?: PostWurdFichesListStoreFields
    fiches_list_delete?: FichesListDeleteStoreFields
    fiche_put?: PutWurdFicheStoreFields
    fiches_list_dashboard_general_get?: GetWurdFichesListDashboardGeneralStoreFields
}

export const wurdWordsListInitialState: WurdWordsListStoreState = {
}

export type WurdWordsListActionTypes = GetWurdWordsListsAction | GetWurdWordsListAction | GetWurdFichesListAction
    | PutWurdFicheListsAction | PutWurdFichesListListsAction | PostWordsListAction | PostWurdFichesListListsAction
    | ClearWurdWordsListAction | GetWurdFichesListDashboardGeneralAction | FichesListDeleteAction

