import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { UserStoreState, UserThunk } from "../types"
import { GroupDTO } from "./userGroupsGet"

export interface UserDTO {
    id: number
    name: string
    googleCode?: string
}

export interface GroupWithUsersDTO extends GroupDTO {
    users: UserDTO[]
}

/// GROUP_GET

export const GROUP_GET = 'GROUP_GET'

export interface GroupGetParams extends ApiCallBaseData {
    groupId: number
}

export const groupGet = (params: GroupGetParams): UserThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/groups/' + params.groupId

        return callGetDispatchedApiSameName<GroupWithUsersDTO, UserStoreState, void, GroupGetParams>(
            apiService, path, dispatch, GROUP_GET, undefined, params);

    }
}

export interface GroupStoreFields extends GetObjectStoreFieldsWithParams<GroupWithUsersDTO, GroupGetParams> { }

export interface GroupAction extends GroupStoreFields {
    type: typeof GROUP_GET
}