import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { DishRecipeDTO } from "../../dish/store/types"
import { FoodStoreState, FoodThunk } from "../../types"
import { MealsPlanDTO } from "./mealsPlanGet"



export interface MealsPlanDashboardTodayTomorrowDTO {
    mealsPlans: MealsPlanDTO[]
    dishes: DishRecipeDTO[]
}

/// MEALS_PLAN_DASHBOARD_TODAY_TOMORROW_GET

export const MEALS_PLAN_DASHBOARD_TODAY_TOMORROW_GET = 'MEALS_PLAN_DASHBOARD_TODAY_TOMORROW_GET'

export interface MealsPlanDashboradTodayTomorrowGetParams extends ApiCallBaseData {

}

export const mealsPlanDashboardTodayTomorrowGet = (params: MealsPlanDashboradTodayTomorrowGetParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/dashboard/today_tomorrow'

        return callGetDispatchedApiSameName<MealsPlanDashboardTodayTomorrowDTO, FoodStoreState, void, MealsPlanDashboradTodayTomorrowGetParams>(
            apiService, path, dispatch, MEALS_PLAN_DASHBOARD_TODAY_TOMORROW_GET, undefined, params);

    }
}

export interface MealsPlanDashboardTodayTomorrowStoreFields extends GetObjectStoreFieldsWithParams<MealsPlanDashboardTodayTomorrowDTO, MealsPlanDashboradTodayTomorrowGetParams> { }

export interface MealsPlanDashboardTodayTomorrowAction extends MealsPlanDashboardTodayTomorrowStoreFields {
    type: typeof MEALS_PLAN_DASHBOARD_TODAY_TOMORROW_GET
}