import { DeleteObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callDeleteDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { BudgetStoreState, BudgetThunk } from "../../types"

/// BUDGET_PLAN_DELETE


export const BUDGET_PLAN_DELETE = 'BUDGET_PLAN_DELETE'

export interface BudgetPlanDeleteParams extends ApiCallBaseData {
    id: number
}

export const budgetPlanDelete = (params: BudgetPlanDeleteParams): BudgetThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/api/budget/plan/' + params.id

        return callDeleteDispatchedApiSameName<BudgetStoreState>(
            apiService, path, dispatch, BUDGET_PLAN_DELETE, params.id);

    }
}

export interface BudgetPlanDeleteStoreFields extends DeleteObjectStoreFields { }

export interface BudgetPlanDeleteAction extends BudgetPlanDeleteStoreFields {
    type: typeof BUDGET_PLAN_DELETE
}