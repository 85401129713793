import { Reducer } from 'redux'
import { reducerStateModifySubstore } from 'src/redux/reducers/reducerStateModifySubstore'
import { CLEAR_USERS_STORE_PARTIAL, UserActionTypes, UserStoreState, UserThunk } from './types'

import { USER_ROLES } from './store/setUserRoles'
import { USER_GROUPS_GET } from './store/userGroupsGet'
import { GROUP_GET } from './store/groupGet'
import { clearPartialSameNameGeneric } from 'src/redux/actions/clearPartialGeneric'
import { USER_GOOGLE_CODE_PUT } from './store/userGoogleCodePut'

export const userStoreInitialState: UserStoreState = {}


export const UserActionNames: string[] = [USER_ROLES, USER_GROUPS_GET, GROUP_GET, CLEAR_USERS_STORE_PARTIAL, USER_GOOGLE_CODE_PUT]

export const userReducer: Reducer<UserStoreState, UserActionTypes> = (state = userStoreInitialState, action: UserActionTypes) => {

    if (UserActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action)
    return state

}

export const clearUsersStorePartial = (part: string): UserThunk => {
    return (dispatch) => {
      clearPartialSameNameGeneric<UserStoreState>(part, CLEAR_USERS_STORE_PARTIAL, dispatch);
    }
  }