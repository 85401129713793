



import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { BudgetStoreState, BudgetThunk } from "../../types"
import { ApplicationState } from "src/redux/reducers/rootReducer"

export interface BudgetPlanMonthlyGroupedDTO {
    month: number
    year: number
    bankingTransactionCategoryId: number
    amountBudgeted?: number
    amountSpent?: number
}

export interface BudgetPlanMonthlyStatsDTO {
    month: number
    year: number
    sumPlannedBudgeted?: number
    sumSpentBudgeted?: number
    sumSpentNotBudgeted?: number
}

export interface BudgetPlansMonthlyGroupedDTO {
    plans: BudgetPlanMonthlyGroupedDTO[]
    stats: BudgetPlanMonthlyStatsDTO[]
}

// BUDGET_PLANS_GET

export const BUDGET_PLANS_GET = 'BUDGET_PLANS_GET'

export interface BudgetPlansGetParams extends ApiCallBaseData {
    start?: Date
    end?: Date
    relevantEnum?: number
    balanceEnum?: number
    isAveragePerCategoryOn?: boolean // wyświetlanie podsumowań per miesiąc
}

export const budgetPlansGet = (params: BudgetPlansGetParams): BudgetThunk => {
    return (dispatch, getStore, { apiService }) => {

        var path: string = '/api/budget/plans'
        const state = getStore() as unknown as ApplicationState

        if (params.relevantEnum == undefined)
            params.relevantEnum = state.budget.monthly.budget_plans_get?.params?.balanceEnum ?? 1

        const td = new Date()
        if (params.start === undefined)
            params.start = state.budget.monthly.budget_plans_get?.params?.start ?? new Date(td.getFullYear(), td.getMonth() - 1, 1)

        if (params.end === undefined)
            params.end = state.budget.monthly.budget_plans_get?.params?.end ?? new Date(td.getFullYear(), td.getMonth() + 2, 1)

        if (params.balanceEnum === undefined)
            params.balanceEnum = state.budget.monthly.budget_plans_get?.params?.balanceEnum


        path += '?relevantEnum=' + params.relevantEnum
        if (params.start !== undefined)
            path += '&start=' + params.start.toLocaleDateString('en-CA')
        if (params.end !== undefined)
            path += '&end=' + params.end.toLocaleDateString('en-CA')

        if (params.balanceEnum !== undefined)
            path += '&balanceEnum=' + params.balanceEnum

        return callGetDispatchedApiSameName<BudgetPlansMonthlyGroupedDTO, BudgetStoreState, void, BudgetPlansGetParams>(
            apiService, path, dispatch, BUDGET_PLANS_GET, undefined, params);

    }
}

export interface BudgetPlansGetStoreFields extends GetObjectStoreFieldsWithParams<BudgetPlansMonthlyGroupedDTO, BudgetPlansGetParams> { }

export interface BudgetPlansGetAction extends BudgetPlansGetStoreFields {
    type: typeof BUDGET_PLANS_GET
}