



import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { BudgetStoreState, BudgetThunk } from "../../types"
import { ApplicationState } from "src/redux/reducers/rootReducer"

export interface AmountWithDateDTO {
    amount: number
    dated: string
}
export interface BudgetForecastDTO {
    amounts: AmountWithDateDTO[]
    balance: AmountWithDateDTO[]
}

// BUDGET_FORECAST_GET

export const BUDGET_FORECAST_GET = 'BUDGET_FORECAST_GET'

export interface BudgetForecastGetParams extends ApiCallBaseData {
    start?: Date
    end?: Date
}


export const budgetForecastGet = (params: BudgetForecastGetParams): BudgetThunk => {
    return (dispatch, getStore, { apiService }) => {

        const state = getStore() as unknown as ApplicationState
        
        const td = new Date()
        if (params.start == undefined)
            params.start = state.budget.monthly.budget_forecast_get?.params?.start ?? new Date(td.getFullYear(), td.getMonth() - 1, 1)
        
        if (params.end == undefined)
            params.end = state.budget.monthly.budget_forecast_get?.params?.end ?? new Date(td.getFullYear(), td.getMonth() + 2, 1)

        var path: string = '/api/budget/forecast?start=' + params.start.toLocaleDateString('en-CA') + '&end=' + params.end.toLocaleDateString('en-CA')


        return callGetDispatchedApiSameName<BudgetForecastDTO, BudgetStoreState, void, ApiCallBaseData>(
            apiService, path, dispatch, BUDGET_FORECAST_GET, undefined, params);

    }
}

export interface BudgetForecastGetStoreFields extends GetObjectStoreFieldsWithParams<BudgetForecastDTO, BudgetForecastGetParams> { }

export interface BudgetForecastGetAction extends BudgetForecastGetStoreFields {
    type: typeof BUDGET_FORECAST_GET
}