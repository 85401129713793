import { ApiError } from "src/services/ApiService"




export interface RequirementPeriodCoreDTO {
    productName: string
    requirementName: string
    periodDateStart: string
    periodDateEnd: string
    valueCalculated: number
    timeCalculated?: string
    goal: number
    goalAchieved: boolean
    statusComputing: string
    financialProductId: number
    requirementId: number
    requirementPeriodId: number
}

export interface RequirementPeriodDTO extends RequirementPeriodCoreDTO {
    topLevelName: string
    daysLeft: number
    financialProductTopLevelId: number
    requirementPeriodValueId: number

}

export interface ProductsRequirementsMonthDTO {
    requirements: RequirementPeriodDTO[]
}

export const GET_PRODUCTS_REQUIREMENTS_MONTH = 'GET_PRODUCTS_REQUIREMENTS_MONTH'

export interface ProductsRequirementsMonthStoreFields extends ProductsRequirementsMonthDTO { }

export interface ProductsRequirementsMonthAction extends ProductsRequirementsMonthStoreFields {
    type: typeof GET_PRODUCTS_REQUIREMENTS_MONTH
}




/// STORE

export interface ProductsRequirementsMonthStoreState {
    requirements?: ProductsRequirementsMonthStoreFields
}


export const productsRequirementsMonthInitialState: ProductsRequirementsMonthStoreState = {
}

export type ProductsRequirementsMonthActionTypes = ProductsRequirementsMonthAction

