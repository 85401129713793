import { PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { WordAttributeUpdateDTO, WordDTO } from "../../words_list/store/wordsListTypes"
import { WurdStoreState, WurdThunk } from "../../wurdStoreTypes"


export interface WordUpdateDTO {
    basicForm?: string
    isWrong?: boolean
    partOfSpeechId?: number
    wordAtrributes?: WordAttributeUpdateDTO[]
}

/// WORD_PUT

export const WORD_PUT = 'WORD_PUT'

export interface WordPutParams extends ApiCallBaseData {
    dto: WordUpdateDTO
    wordId: number
    updateOnlyNotNull: boolean

}

export const wordPut = (params: WordPutParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/word?wordId=' + params.wordId
        if (!params.updateOnlyNotNull)
          path += '&updateOnlyNotNull=false'
        
        return callPutDispatchedApiSameName<WordDTO, WurdStoreState, WordUpdateDTO, WordPutParams>(
            apiService, path, dispatch, WORD_PUT, params.dto, params);

    }
}

export interface WordPutStoreFields extends PutObjectStoreFields<WordDTO> { }

export interface WordPutAction extends WordPutStoreFields {
    type: typeof WORD_PUT
}