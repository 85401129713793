import { PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO, ProductsValues } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk } from "../../types"
import { MealsPlanDTO, MealsPlanUpdateableDTO } from "./mealsPlanGet"


/// MEALS_PLAN_POST



export const MEALS_PLAN_POST = 'MEALS_PLAN_POST'

export interface MealsPlanPostParams {
    dto: MealsPlanUpdateableDTO
}

export const mealsPlanPost = (params: MealsPlanPostParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan';
        
        return callPostDispatchedApiSameName<MealsPlanDTO, FoodStoreState, MealsPlanUpdateableDTO>(
            apiService, path, dispatch, MEALS_PLAN_POST, params.dto);

    }
}

export interface MealsPlanPostStoreFields extends PostObjectStoreFields<MealsPlanDTO> { }

export interface MealsPlanPostAction extends MealsPlanPostStoreFields {
    type: typeof MEALS_PLAN_POST
}