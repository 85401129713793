import { PaginatedListParams } from "src/interfaces/PaginatedList"
import { BankingTransactionKindEnum } from "./types"
import { BankingStoreState, BankingThunk } from "../../types"
import { polishFloatToStandardString } from "src/utility/converters"
import { callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApplicationState } from "src/redux/reducers/rootReducer"
import { BankingTransactionUploadDTO } from "./bankingTransactionPut"



export interface BankingTransactionWithAccountDTO extends BankingTransactionUploadDTO {
    id: number
    bankingPersonalAccountId: number
    amount: number
    transactionOn: Date
    bookedOn: Date
    kind?: string
    title?: string
    party?: string
    currencyAmount: number
    plainPartyIban?: string
    description?: string
    isRemoved: boolean
    isExcludedFromCalculations: boolean
    uidKontomierz?: string
    currencyName?: string
    tagString?: string
    extraPersonalAccountActionFlag?: number
    extraCategoryConfirmationFlag?: number
    extraNotifcationSentFlag?: number
    extraCurveReturnRelation?: number
    extraReturnRelation?: number
    extraLoanRelation?: number
    accountName?: string
    bankName?: string
    isBlocked?: boolean
    unifiedKind?: BankingTransactionKindEnum
}

export interface BankingTransactionWithAccountPaginatedDTO {
    pageIndex: number
    totalPages: number
    totalCount: number
    hasPreviousPage: boolean
    hasNextPage: boolean
    items: BankingTransactionWithAccountDTO[]
}

export const BANKING_TRANSACTIONS_GET = 'BANKING_TRANSACTIONS_GET'

export interface BankingTransactionsGetParams extends PaginatedListParams {
    categoryConfirmationFlagFilter?: number
    categoryIdFilter?: number
    minAmountFilter?: number
    maxAmountFilter?: number
    minTransactionDateFilter?: Date
    maxTransactionDateFilter?: Date
    textLikeFilter?: string
    accountId?: string
}

export interface BankingTransactionsGetExtendedParams extends BankingTransactionsGetParams {
    isOneTime?: boolean // default false, true nie aktualizujemy store w kontekscie parametrów wyszukiwania TODO niezaimplementowane zapisywanie do store parametrów wyszukiwania
    reuseStoreCachedParameters?: boolean
}


export const bankingTransactionsGet = (params: BankingTransactionsGetExtendedParams): BankingThunk => {
    return (dispatch, getStore, { apiService }) => {


        let params_temp: BankingTransactionsGetExtendedParams = {};

        if (params.reuseStoreCachedParameters === true) {
            const state = getStore() as unknown as ApplicationState
            params_temp = {  ...state.banking.transactions.banking_transactions_get?.params, ...params}; // pierwsze w kolejności idą zcacheowane wartości, później nowe

            console.log(params)
            console.log(state.banking.transactions.banking_transactions_get?.params)
            console.log(params_temp)
        } else
            params_temp = params;

        var path: string = '/banking/transactions'
        if (params_temp.pageNumber === undefined)
            path += '?pageNumber=1'
        else
            path += '?pageNumber=' + params_temp.pageNumber

        if (params_temp.pageSize !== undefined)
            path += '&pageSize=' + params_temp.pageSize

        if (params_temp.categoryConfirmationFlagFilter !== undefined)
            path += '&categoryConfirmationFlagFilter=' + params_temp.categoryConfirmationFlagFilter

        if (params_temp.categoryIdFilter !== undefined)
            path += '&categoryIdFilter=' + params_temp.categoryIdFilter

        if (params_temp.minAmountFilter !== undefined)
            path += '&minAmountFilter=' + polishFloatToStandardString(params_temp.minAmountFilter)

        if (params_temp.maxAmountFilter !== undefined)
            path += '&maxAmountFilter=' + polishFloatToStandardString(params_temp.maxAmountFilter)

        if (params_temp.minTransactionDateFilter !== undefined)
            path += '&minTransactionDateFilter=' + params_temp.minTransactionDateFilter.toLocaleDateString('en-CA')

        if (params_temp.maxTransactionDateFilter !== undefined)
            path += '&maxTransactionDateFilter=' + params_temp.maxTransactionDateFilter.toLocaleDateString('en-CA')

        if (params_temp.textLikeFilter !== undefined && params_temp.textLikeFilter.length > 0)
            path += '&textLikeFilter=' + params_temp.textLikeFilter

        if (params_temp.accountId !== undefined)
            path += '&accountId=' + params_temp.accountId

        return callGetDispatchedApiSameName<BankingTransactionWithAccountPaginatedDTO, BankingStoreState, void, BankingTransactionsGetExtendedParams>(
            apiService, path, dispatch, BANKING_TRANSACTIONS_GET, undefined, params_temp);

        // return apiService.callDispatchedApi<BankingTransactionWithAccountPaginatedDTO>(axios.get,
        //     path,
        //     response => {
        //         dispatch({
        //             type: BANKING_TRANSACTIONS_GET,
        //             transactions: {
        //                 items: response.data.items,
        //                 totalPages: response.data.totalPages,
        //                 pageIndex: response.data.pageIndex,
        //                 hasPreviousPage: response.data.hasPreviousPage,
        //                 hasNextPage: response.data.hasNextPage,
        //                 totalCount: response.data.totalCount,
        //                 queryParams: {
        //                     pageNumber: params.pageNumber,
        //                     pageSize: params.pageSize,
        //                     categoryConfirmationFlagFilter: params.categoryConfirmationFlagFilter,
        //                     categoryIdFilter: params.categoryIdFilter,
        //                     minAmountFilter: params.minAmountFilter,
        //                     maxAmountFilter: params.maxAmountFilter,
        //                     minTransactionDateFilter: params.minTransactionDateFilter,
        //                     maxTransactionDateFilter: params.maxTransactionDateFilter,
        //                     textLikeFilter: params.textLikeFilter,
        //                     accountId: params.accountId
        //                 }
        //             },
        //         })
        //     }
        // )
    }
}

export interface BankingTransactionsGetStoreFields extends GetObjectStoreFieldsWithParams<BankingTransactionWithAccountPaginatedDTO, BankingTransactionsGetExtendedParams> { }

export interface BankingTransactionsGetAction extends BankingTransactionsGetStoreFields {
    type: typeof BANKING_TRANSACTIONS_GET
}

