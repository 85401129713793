import { PaginatedList, PaginatedListParams } from "src/interfaces/PaginatedList"
import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk, FoodThunkDispatch } from "../../types"
import { MealsPlanDTO } from "./mealsPlanGet"
import { v4 as uuidv4 } from 'uuid';

/// MEALS_PLANS_GET

export const MEALS_PLANS_GET = 'MEALS_PLANS_GET'

export interface MealsPlansPaginatedDTO extends PaginatedList<MealsPlanDTO> {
}


export interface MealsPlansGetParams extends PaginatedListParams, ApiCallBaseData {

}

export const mealsPlansGet = (params: MealsPlansGetParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/'
        path = concatApiUrl(path, params.pageNumber, "pageNumber", 1)
        path = concatApiUrl(path, params.pageSize, "pageSize")

        return callGetDispatchedApiSameName<MealsPlansPaginatedDTO, FoodStoreState, void, MealsPlansGetParams>(
            apiService, path, dispatch, MEALS_PLANS_GET, undefined, params);

    }
}

export interface MealsPlansGetStoreFields extends GetObjectStoreFieldsWithParams<MealsPlansPaginatedDTO, MealsPlansGetParams> { }

export interface MealsPlansGetAction extends MealsPlansGetStoreFields {
    type: typeof MEALS_PLANS_GET
}

/// EXTENSIONS

export const mealsPlansGetStoreBased = (dispatch: FoodThunkDispatch, store: FoodStoreState, pageNumberOverride?: number, pageSizeOverride?: number) => {
    dispatch(mealsPlansGet({
      pageNumber: pageNumberOverride ?? store.meals_plan.meals_plans_get?.params?.pageNumber,
      pageSize: pageSizeOverride ?? store.meals_plan.meals_plans_get?.params?.pageSize,
      uuid: uuidv4()
    }))
  }