import { PostObjectStoreFields, PostObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ReactSelectNumericOptionType } from "src/views/banking/shared/types/SelectOptionTypes"
import { ProductsDTO, ProductsValues } from "../../dicts/store/types"
import { DishVariantDTO } from "../../dish/store/types"
import { FoodStoreState, FoodThunk } from "../../types"

export enum MealTimeOptions {
    Breakfast = 1,
    SecondBreakfast = 2,
    Lunch = 3,
    Tea = 4,
    Supper = 5
}

export const MealTimeOptionsForm: ReactSelectNumericOptionType[] = [
    new ReactSelectNumericOptionType(1, "Śniadanie"),
    new ReactSelectNumericOptionType(2, "Drugie śniadanie"),
    new ReactSelectNumericOptionType(3, "Obiad"),
    new ReactSelectNumericOptionType(4, "Podwieczorek"),
    new ReactSelectNumericOptionType(5, "Kolacja")
]

export interface MealsPlanEntryUpdateableDTO {
    entryDay: string // Date
    quantity: number
    mealTime: MealTimeOptions
    mealsPlanId: number
    dishVariantId: number
    continuingDays: number
    executingUserId?: number
}

export interface MealsPlanEntryDTO extends MealsPlanEntryUpdateableDTO {
    id: number
    dishVariant: DishVariantDTO
    isCookedByMe: boolean
}

/// MEALS_PLAN_ENTRY_POST

export const MEALS_PLAN_ENTRY_POST = 'MEALS_PLAN_ENTRY_POST'

export interface MealsPlanEntryPostParams extends ApiCallBaseData  {
    dto: MealsPlanEntryUpdateableDTO
}

export const mealsPlanEntryPost = (params: MealsPlanEntryPostParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/' + params.dto.mealsPlanId + '/entry';

        return callPostDispatchedApiSameName<MealsPlanEntryDTO, FoodStoreState, MealsPlanEntryUpdateableDTO, MealsPlanEntryPostParams>(
            apiService, path, dispatch, MEALS_PLAN_ENTRY_POST, params.dto, params);

    }
}

export interface MealsPlanEntryPostStoreFields extends PostObjectStoreFieldsWithParams<MealsPlanEntryDTO,MealsPlanEntryPostParams> { }

export interface MealsPlanEntryPostAction extends MealsPlanEntryPostStoreFields {
    type: typeof MEALS_PLAN_ENTRY_POST
}