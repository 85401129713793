import { clearPartialSameNameGeneric } from "src/redux/actions/clearPartialGeneric"
import { FoodStoreState, FoodThunk } from "../../types"
import { ProductDeleteAction, ProductDeleteStoreFields } from "./productDelete"
import { ProductsListGetAction, ProductsListGetStoreFields } from "./productListGet"
import { ProductPostStoreFields, ProductPostAction } from "./productPost"
import { ProductPutAction, ProductPutStoreFields } from "./productPut"
import { ProductConversionListGetAction, ProductConversionListGetStoreFields } from "../conversion/store/productConversionListGet"
import { ProductConversionPostStoreFields, ProductConversionPostAction } from "../conversion/store/productConversionPost"
import { ProductConversionPutStoreFields, ProductConversionPutAction } from "../conversion/store/productConversionPut"
import { ProductDishListGetStoreFields, ProductDishListGetAction } from "../dish/store/productConversionListGet"

/// CLEAR_FOOD_PRODUCT

export const CLEAR_FOOD_PRODUCT = 'CLEAR_FOOD_PRODUCT'

export interface ClearFoodProductAction {
    type: typeof CLEAR_FOOD_PRODUCT
}

export const clearFoodPartial = (part: string): FoodThunk => {
    return (dispatch) => {
        clearPartialSameNameGeneric<FoodStoreState>(part, CLEAR_FOOD_PRODUCT, dispatch);
    }
}

// STORE

export interface FoodProductStoreState {
    product_list_get?: ProductsListGetStoreFields
    product_post?: ProductPostStoreFields
    product_put?: ProductPutStoreFields
    product_delete?: ProductDeleteStoreFields
    product_conversion_list_get?: ProductConversionListGetStoreFields
    product_conversion_post?: ProductConversionPostStoreFields
    product_conversion_put?: ProductConversionPutStoreFields
    product_dish_list_get?: ProductDishListGetStoreFields
}


export type FoodProductActionTypes = ProductsListGetAction | ProductPostAction | ProductPutAction | ProductDeleteAction
    | ProductConversionListGetAction | ProductConversionPostAction | ProductConversionPutAction | ProductDishListGetAction