import { GetObjectStoreFields } from 'src/redux/interfaces/GenericStoreApiResults'
import { BankingStoreState, BankingThunk } from '../../types'
import { BankingCategoryDictsMapped } from './BankingCategoryDictsMapped'
import { ApiCallBaseData, callGetDispatchedApiSameName } from 'src/utility/DispatchWrappers'

export interface BankingCategoryDTO {
  id: number
  name: string
}

export interface BankingCategoryGroupDTO {
  groupTitle: string
  data: BankingCategoryDTO[]
}


export interface BankingCategoryDictsDTO {
  income: BankingCategoryGroupDTO[]
  outcome: BankingCategoryGroupDTO[]
}


export const BANKING_CATEGORIES_GET = 'BANKING_CATEGORIES_GET'

export const bankingCategoriesGet = (params: ApiCallBaseData): BankingThunk => {
  return (dispatch, _getStore, { apiService }) => {

    return callGetDispatchedApiSameName<BankingCategoryDictsDTO, BankingStoreState, BankingCategoryDictsMapped, ApiCallBaseData>(
      apiService, "/banking/categories", dispatch, BANKING_CATEGORIES_GET, x => new BankingCategoryDictsMapped(x.income, x.outcome), params);


  }
}

export interface BankingCategoriesGetActionStoreFields extends GetObjectStoreFields<BankingCategoryDictsMapped> { }

export interface BankingCategoriesGetAction extends BankingCategoriesGetActionStoreFields {
  type: typeof BANKING_CATEGORIES_GET
}
