import { DeleteObjectStoreFields, DeleteObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callDeleteDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk } from "../../types"

/// MEALS_PLAN_ENTRY_DELETE


export const MEALS_PLAN_ENTRY_DELETE = 'MEALS_PLAN_ENTRY_DELETE'

export interface MealsPlanEntryDeleteParams extends ApiCallBaseData {
    id: number
    planId: number

}

export const mealsPlanEntryDelete = (params: MealsPlanEntryDeleteParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/' + params.planId + '/entry/' + params.id

        return callDeleteDispatchedApiSameName<FoodStoreState, MealsPlanEntryDeleteParams>(
            apiService, path, dispatch, MEALS_PLAN_ENTRY_DELETE, params.id, params);

    }
}

export interface MealsPlanEntryDeleteStoreFields extends DeleteObjectStoreFieldsWithParams<MealsPlanEntryDeleteParams> { }

export interface MealsPlanEntryDeleteAction extends MealsPlanEntryDeleteStoreFields {
    type: typeof MEALS_PLAN_ENTRY_DELETE
}