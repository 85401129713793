// ** Redux Imports
import { combineReducers, Reducer } from 'redux'

// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import food from 'src/views/food/reducer'
import banking from 'src/views/banking/reducer'
import products from 'src/views/products/reducer'
import budget from 'src/views/budget/reducer'
import spotify from 'src/views/spotify/reducer'
import wurd from 'src/views/wurd/wurdReducer'
import tasks from 'src/views/tasks/reducer'
import { userReducer as user} from 'src/views/user/reducer'
import { FoodActionTypes, FoodStoreState } from 'src/views/food/types'
import { BankingStoreState } from 'src/views/banking/types'
import { ProductsStoreState } from 'src/views/products/types'
import { BudgetStoreState } from 'src/views/budget/types'
import { SpotifyStoreState } from 'src/views/spotify/types'
import { WurdStoreState } from 'src/views/wurd/wurdStoreTypes'
import { UserStoreState } from 'src/views/user/types'
import { TasksStoreState } from 'src/views/tasks/types'

export interface ApplicationState {
  navbar: ReturnType<typeof navbar>
  layout: ReturnType<typeof layout>
  food: FoodStoreState
  banking: BankingStoreState
  products: ProductsStoreState
  budget: BudgetStoreState 
  spotify: SpotifyStoreState
  wurd: WurdStoreState
  user: UserStoreState
  tasks: TasksStoreState
}

export type ApplicationActions = FoodActionTypes;

const rootReducer: Reducer<ApplicationState, ApplicationActions> = combineReducers<ApplicationState>({
  navbar,
  layout,
  food,
  banking,
  products,
  budget,
  spotify,
  wurd,
  user,
  tasks
})

export default rootReducer;