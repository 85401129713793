import { ApiError } from "src/services/ApiService"



export interface ProductsTreeEntryDTO {
    id: number
    ownerProductId?: number
    financialProductTypeId: number
    name: string
    personDataId: number
    status: string
    children: ProductsTreeEntryDTO[]

}

export interface ProductsTreeDTO {

    rootNodes: ProductsTreeEntryDTO[]
}

export const GET_PRODUCTS_TREE = 'GET_PRODUCTS_TREE'

export interface ProductsTreeStoreFields extends ProductsTreeDTO { }

export interface ProductsTreeAction extends ProductsTreeStoreFields {
    type: typeof GET_PRODUCTS_TREE
}


/// STORE

export interface ProductsTreeStoreState {
    tree?: ProductsTreeStoreFields
    treeFilter: string
}


export const productsTreeInitialState: ProductsTreeStoreState = {
    treeFilter: '1'
}

export type ProductsTreeActionTypes = ProductsTreeAction

