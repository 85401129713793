import { GetObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiError } from "src/services/ApiService"
import { GetBankingDashboardGeneralAction, GetBankingDashboardGeneralStoreFields } from "./bankingDashboardGeneralGet"

export interface BankingAccountCurrentStatusDTO {
    accountName: string
    balance: number
    fundsAvailable?: number
    id: number
    fundsBlocked: number
}

export interface BankAccountsDTO {
    bankName: string
    accounts: BankingAccountCurrentStatusDTO[]

}

export interface BankingAccountDashboardDTO {

    personal: BankAccountsDTO[]
    saving: BankAccountsDTO[]
    credit: BankAccountsDTO[]
}

// GET_BANKING_DASHBOARD_ACCOUNTS

export const GET_BANKING_DASHBOARD_ACCOUNTS = 'GET_BANKING_DASHBOARD_ACCOUNTS'

export interface BankingAccountDashboardStoreFields extends BankingAccountDashboardDTO { }

export interface BankingAccountDashboardAction extends BankingAccountDashboardStoreFields {
    type: typeof GET_BANKING_DASHBOARD_ACCOUNTS
}


// PUT_BANKING_ACCOUNT

export interface BankAccountUpdateable {
    isSavings: boolean
    isArchived: boolean
    isCredit: boolean
    overrideDisplayName?: string
}

export interface BankAccountFull extends BankAccountUpdateable {
    id: number
    iban: string
    balance: number
    currencyBalance: number
    currencyFundsAvailable?: number
    displayName: string
    bankName: string
    currencyName: string
}

export const PUT_BANKING_ACCOUNT = 'PUT_BANKING_ACCOUNT'

export interface PutBankingAccountStoreFields extends GetObjectStoreFields<BankAccountFull> { }

export interface PutBankingAccountAction extends PutBankingAccountStoreFields {
    type: typeof PUT_BANKING_ACCOUNT
}

// GET_BANKING_ACCOUNT

export const GET_BANKING_ACCOUNT = 'GET_BANKING_ACCOUNT'

export interface GetBankingAccountStoreFields extends GetObjectStoreFields<BankAccountFull> { }

export interface GetBankingAccountAction extends GetBankingAccountStoreFields {
    type: typeof GET_BANKING_ACCOUNT
}

// CLEAR_ACCOUNTS

export const CLEAR_ACCOUNTS = 'CLEAR_ACCOUNTS'

export interface ClearAccountsAction {
    type: typeof CLEAR_ACCOUNTS
}



/// STORE

export interface BankingAccountsStoreState {
    dashboard?: BankingAccountDashboardStoreFields
    account_get?: GetBankingAccountStoreFields
    account_put?: PutBankingAccountStoreFields
    banking_dashboard_general_get?: GetBankingDashboardGeneralStoreFields
}


export const bankingAccountsInitialState: BankingAccountsStoreState = {
}

export type BankingAccountsActionTypes = BankingAccountDashboardAction | GetBankingAccountAction | PutBankingAccountAction | ClearAccountsAction | GetBankingDashboardGeneralAction

