import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { CalledObjectStoreFieldsWithParams, GetObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults";
import { ThunkExtraArguments } from "src/redux/storeConfig/store";
import { ApiCallBaseData } from "src/utility/DispatchWrappers";
import { FoodDictsMapped } from "./FoodDictsMapped";
import { FoodTagDTO } from "../../tags/store/tagPost";

export interface DictionarySingleIntegerKey {
    id: number
}

export interface FoodUnitsValues {
    name: string
    namePluralNominativ: string
    namePluralGenitive: string
    mainAmount: number
}

export interface FoodUnitsDTO extends DictionarySingleIntegerKey, FoodUnitsValues {}

interface NutritionalGoalsValues {
    isActive: true
    name: string
    curMass: number
    height: number
    age: number
    pal: number
    prtnMltpr: number
    prtnTlrDwn: number
    prtnTlrUp: number
    crbhdtsMltpr: number
    cpmMltpr: number
    fiberTlrDwn: number
    fiberTlrUp: number
    energyTlrDwn: number
    energyTlrUp: number
}

export interface NutritionalGoalsDTO extends DictionarySingleIntegerKey, NutritionalGoalsValues {}

export interface ProductCategoriesValues {
    name: string
}

export interface ProductCategoriesDTO extends DictionarySingleIntegerKey, ProductCategoriesValues {}

interface ProductUnitConversionsKeys {
    id: number
    productId: number
    secondUnitId: number
}

export interface ProductUnitConversionsValues {
    conversion: number
}

export interface ProductUnitConversionsDTO extends ProductUnitConversionsKeys, ProductUnitConversionsValues {}

export interface ProductsValues {
    name: string,
    categoryId: number,
    nameGenitiveSingular: string,
    nameGenitivePlurar: string,
    mainUnitId: number,
    ntrEnergy?: number,
    ntrPrtn?: number,
    ntrFat?: number,
    ntrCrbhdrt?: number,
    ntrFiber?: number,
    ntrSodium?: number,
    ntrNkt?: number,
    canEdit: boolean // readonly
    tags: number[]
}

export interface ProductsDTO extends DictionarySingleIntegerKey, ProductsValues {}

interface GetFoodDictionariesActionStoreFields extends CalledObjectStoreFieldsWithParams<FoodDictsMapped, ApiCallBaseData> {
}


export const DICTS_FOOD_GET = 'DICTS_FOOD_GET'

export interface GetDictsFoodActionDTO {
    foodUnits: FoodUnitsDTO[],
    nutritionalGoals: NutritionalGoalsDTO[],
    productCategories: ProductCategoriesDTO[],
    productUnitConversions: ProductUnitConversionsDTO[],
    products: ProductsDTO[]
    tags: FoodTagDTO[]
}

export interface GetDictsFoodAction extends GetFoodDictionariesActionStoreFields {
    type: typeof DICTS_FOOD_GET
}

/// STORE

export interface FoodDictionariesStoreState {
    dicts_food_get?: GetFoodDictionariesActionStoreFields
}

export const foodDictionariesInitialState: FoodDictionariesStoreState = {

}

export type FoodDictionariesActionTypes = GetDictsFoodAction

