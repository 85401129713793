import { PostObjectStoreFields, PostObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { MealsPlanEntryDTO } from "src/views/food/meals_plan/store/mealsPlanEntryPost"
import { WordAttributeUpdateDTO, WordDTO } from "../../words_list/store/wordsListTypes"
import { WurdThunk, WurdStoreState } from "../../wurdStoreTypes"

export interface WordCreateDTO {
    partOfSpeechId: number
    basicForm: string
    isWrong: boolean
    wordAtrributes: WordAttributeUpdateDTO[]
}

/// WORD_POST

export const WORD_POST = 'WORD_POST'

export interface WordPostParams extends ApiCallBaseData  {
    dto: WordCreateDTO
}

export const wordPost = (params: WordPostParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/word'

        return callPostDispatchedApiSameName<WordDTO, WurdStoreState, WordCreateDTO, WordPostParams>(
            apiService, path, dispatch, WORD_POST, params.dto, params);

    }
}


export interface WordPostStoreFields extends PostObjectStoreFieldsWithParams<WordDTO,WordPostParams> { }

export interface WordPostAction extends WordPostStoreFields {
    type: typeof WORD_POST
}