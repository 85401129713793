


/// CLEAR_FOOD_MEALS_PLAN

import { MealsPlanDashboardTodayTomorrowStoreFields, MealsPlanDashboardTodayTomorrowAction } from "./mealsPlanDashboardTodayTomorrowGet"
import { MealsPlanEntryDeleteAction, MealsPlanEntryDeleteStoreFields } from "./mealsPlanEntryDelete"
import { MealsPlanEntryPostAction, MealsPlanEntryPostStoreFields } from "./mealsPlanEntryPost"
import { MealsPlanEntryPutAction, MealsPlanEntryPutStoreFields } from "./mealsPlanEntryPut"
import { MealsPlanGetAction, MealsPlanGetStoreFields } from "./mealsPlanGet"
import { MealsPlanGetDetailedAction, MealsPlanGetDetailedStoreFields } from "./mealsPlanGetDetailed"
import { MealsPlanGetMacrosAction, MealsPlanGetMacrosStoreFields } from "./mealsPlanGetMacros"
import { MealsPlanGetShoppingListAction, MealsPlanGetShoppingListStoreFields } from "./mealsPlanGetShoppingList"
import { MealsPlanPostAction, MealsPlanPostStoreFields } from "./mealsPlanPost"
import { MealsPlanPutAction, MealsPlanPutStoreFields } from "./mealsPlanPut"
import { MealsPlanShoppingListListonicPostAction, MealsPlanShoppingListListonicPostStoreFields } from "./mealsPlanShoppingListListonicPost"
import { MealsPlansGetStoreFields, MealsPlansGetAction } from "./mealsPlansGet"

export const CLEAR_FOOD_MEALS_PLAN = 'CLEAR_FOOD_MEALS_PLAN'

export interface ClearFoodMealsPlanAction {
    type: typeof CLEAR_FOOD_MEALS_PLAN
}

// STORE

export interface FoodMealsPlanStoreState {
    meals_plan_get?: MealsPlanGetStoreFields
    meals_plan_get_detailed?: MealsPlanGetDetailedStoreFields
    meals_plan_get_macros?: MealsPlanGetMacrosStoreFields
    meals_plan_post?: MealsPlanPostStoreFields
    meals_plan_put?: MealsPlanPutStoreFields
    meals_plans_get?: MealsPlansGetStoreFields
    meals_plan_entry_delete?: MealsPlanEntryDeleteStoreFields
    meals_plan_entry_post?: MealsPlanEntryPostStoreFields
    meals_plan_entry_put?: MealsPlanEntryPutStoreFields
    meals_plan_shopping_list_get?: MealsPlanGetShoppingListStoreFields
    meals_plan_dashboard_today_tomorrow_get?: MealsPlanDashboardTodayTomorrowStoreFields
    meals_plan_shopping_list_listonic_post?: MealsPlanShoppingListListonicPostStoreFields


}

export type FoodMealsPlanActionTypes = MealsPlanGetAction | MealsPlanGetDetailedAction | MealsPlanPostAction | MealsPlansGetAction | ClearFoodMealsPlanAction | MealsPlanPutAction
    | MealsPlanEntryDeleteAction | MealsPlanEntryPostAction | MealsPlanEntryPutAction | MealsPlanGetShoppingListAction | MealsPlanDashboardTodayTomorrowAction | MealsPlanShoppingListListonicPostAction
    | MealsPlanGetMacrosAction