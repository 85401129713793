import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk } from "../../types"
import { MealsPlanEntryDTO } from "./mealsPlanEntryPost"


export interface MealsPlanUpdateableDTO {
    name: string
    groupId: number
}

export interface MealsPlanDTO extends MealsPlanUpdateableDTO {
    id: number
    mealsPlanEntries: MealsPlanEntryDTO[]
}

/// MEALS_PLAN_GET

export const MEALS_PLAN_GET = 'MEALS_PLAN_GET'

export interface MealsPlanGetParams extends ApiCallBaseData {
    id: number

}

export const mealsPlanGet = (params: MealsPlanGetParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/' + params.id

        return callGetDispatchedApiSameName<MealsPlanDTO, FoodStoreState, void, MealsPlanGetParams>(
            apiService, path, dispatch, MEALS_PLAN_GET, undefined, params);

    }
}

export interface MealsPlanGetStoreFields extends GetObjectStoreFieldsWithParams<MealsPlanDTO, MealsPlanGetParams> { }

export interface MealsPlanGetAction extends MealsPlanGetStoreFields {
    type: typeof MEALS_PLAN_GET
}