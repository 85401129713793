import { DishesPaginatedDTO } from './types'
import { FoodStoreState, FoodThunk } from '../../types'
import { ApplicationState } from 'src/redux/reducers/rootReducer'
import { PaginatedListParams } from 'src/interfaces/PaginatedList'
import { ApiCallBaseData, callGetDispatchedApiSameName } from 'src/utility/DispatchWrappers'
import { GetObjectStoreFieldsWithParams } from 'src/redux/interfaces/GenericStoreApiResults'
import { v4 as uuidv4 } from 'uuid';
import { LoggingAreaEnum, logCustom } from 'src/helpers/logger'

export const DISHES_GET = 'DISHES_GET'

export interface DishesGetParams extends PaginatedListParams, ApiCallBaseData {
  searchString?: string
  filterType?: string // Typ filtra: all (domyślnie), breakfast, second_breakfast, lunch, tea, supper
  tagId?: number
  reuseStoreCachedParameters?: boolean
}


export const dishesGet = (params: DishesGetParams): FoodThunk => {
  return (dispatch, getState, { apiService }) => {

    let params_temp: DishesGetParams = { uuid: uuidv4() };
    logCustom(LoggingAreaEnum.DISHES_GET,"dishesGet",params,"parametry przychodzące")

    if (params.reuseStoreCachedParameters === true) {
      const state = getState() as unknown as ApplicationState
      logCustom(LoggingAreaEnum.DISHES_GET,"dishesGet",state.food.dashboard.dishes_get?.params,"parametry istniejące w store")
      params_temp = {  ...state.food.dashboard.dishes_get?.params, ...params };
    } else
      params_temp = params;

    if (params_temp.pageNumber === undefined) params_temp.pageNumber = 1;
    if (params_temp.searchString === '') params_temp.searchString = undefined;
    if (params_temp.filterType === 'all') params_temp.filterType = undefined;

    logCustom(LoggingAreaEnum.DISHES_GET,"dishesGet",params_temp,"parametry docelowe")

    var path: string = '/food/dishes?pageSize=24'

    path += '&pageNumber=' + params_temp.pageNumber

    if (params_temp.searchString !== undefined)
      path += '&searchString=' + params_temp.searchString

    if (params_temp.filterType !== undefined)
      path += '&filterType=' + params_temp.filterType

    if (params_temp.tagId !== undefined)
      path += '&tagId=' + params_temp.tagId

    return callGetDispatchedApiSameName<DishesPaginatedDTO, FoodStoreState, void, DishesGetParams>(
      apiService, path, dispatch, DISHES_GET, undefined, params_temp);

  }
}

export interface DishesGetStoreFields extends GetObjectStoreFieldsWithParams<DishesPaginatedDTO, DishesGetParams> { }

export interface DishesGetAction extends DishesGetStoreFields {
  type: typeof DISHES_GET
}