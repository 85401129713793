import { GetObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults";
import { WurdDictsMapped } from "./WurdDictsMapped";


/// GET_WURD_DICTIONARIES 

export interface PartOfSpeechTypeDTO {
    id: number
    type: string
}

export interface PartOfSpeechAttributeValueDTO {
    id: number
    partOfSpeechAttributeId: number
    value: string
}

export enum AtrributeTypes {
    String = 1,
    Dictionary = 2,
    Boolean = 3
}

export interface PartOfSpeechAttributeDTO {
    id: number
    partOfSpeechId: number
    name: string
    // isDictProvided: boolean
    displayOrder: number
    atrributeType: AtrributeTypes
}

export interface PartOfSpeechDTO {
    id: number
    name: string
    partOfSpeechTypeId: number
    languageId: number
}

export interface LanguageDTO {
    id: number
    isO6393: string
    name: string
}

export interface WurdDictionariesDTO {
    languages: LanguageDTO[]
    partOfSpeeches: PartOfSpeechDTO[]
    partOfSpeechAttributes: PartOfSpeechAttributeDTO[]
    partOfSpeechAttributeValues: PartOfSpeechAttributeValueDTO[]
    partOfSpeechTypes: PartOfSpeechTypeDTO[]
}


export const DICTS_WURD_GET = 'DICTS_WURD_GET'

interface GetWurdDictionariesActionStoreFields extends GetObjectStoreFields<WurdDictsMapped> {
}

export interface GetWurdDictionariesAction extends GetWurdDictionariesActionStoreFields {
    type: typeof DICTS_WURD_GET
}


/// STORE

export interface WurdDictionariesStoreState {
    dicts_wurd_get?: GetWurdDictionariesActionStoreFields
}

export const bankingDictionariesInitialState: WurdDictionariesStoreState = {

}
export type WurdDictionariesActionTypes = GetWurdDictionariesAction

