import { WordConnectedGetAction, WordConnectedGetStoreFields } from "./wordConnectedGet"
import { WordDeleteAction, WordDeleteStoreFields } from "./wordDelete"
import { WordDuplicateConsolidatePostAction, WordDuplicateConsolidatePostStoreFields } from "./wordDuplicateConsolidatePost"
import { WordDuplicateGetAction, WordDuplicateGetStoreFields } from "./wordDuplicateGet"
import { WordGetAction, WordGetStoreFields } from "./wordGet"
import { WordWrongPatchAction, WordWrongPatchStoreFields } from "./wordWrongPatch"
import { WordPostStoreFields, WordPostAction } from "./wordPost"
import { WordPutStoreFields, WordPutAction } from "./wordPut"
import { WordsAutosuggestGetAction, WordsAutosuggestGetStoreFields } from "./wordsAutosuggestGet"
import { WordsGetStoreFields, WordsGetAction } from "./wordsGet"
import { WordsPairDeleteAction, WordsPairDeleteStoreFields } from "./wordsPairDelete"
import { WordsPairPostStoreFields, WordsPairPostAction } from "./wordsPairPost"



// CLEAR_WURD_WORD

export const CLEAR_WURD_WORD = 'CLEAR_WURD'

export interface ClearWurdWordAction {
    type: typeof CLEAR_WURD_WORD
}


/// STORE

export interface WurdWordsStoreState {
    word_get?: WordGetStoreFields
    words_get?: WordsGetStoreFields
    words_autosuggest_get?: WordsAutosuggestGetStoreFields
    word_post?: WordPostStoreFields
    word_put?: WordPutStoreFields
    word_delete?: WordDeleteStoreFields
    words_pair_post?: WordsPairPostStoreFields
    words_pair_delete?: WordsPairDeleteStoreFields
    word_duplicate_get?: WordDuplicateGetStoreFields
    word_duplicate_consolidate_post?: WordDuplicateConsolidatePostStoreFields
    word_connected_get?: WordConnectedGetStoreFields
    word_wrong_patch?: WordWrongPatchStoreFields
}

export const wurdWordsInitialState: WurdWordsStoreState = {
}

export type WurdWordsActionTypes = WordsGetAction | WordsAutosuggestGetAction | ClearWurdWordAction | WordPostAction
    | WordsPairPostAction | WordPutAction | WordDuplicateGetAction | WordDuplicateConsolidatePostAction | WordGetAction
    | WordConnectedGetAction | WordsPairDeleteAction | WordDeleteAction | WordWrongPatchAction
