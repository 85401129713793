import { Reducer } from 'redux'
import { CLEAR_SPOTIFY, GET_SPOTIFY_TABLE, POST_SPOTIFY_EMAILS, spotifyDashboardInitialState } from './dashboard/store/types'
import { SpotifyActionTypes, SpotifyStoreState } from './types'


export const spotifyInitialState: SpotifyStoreState = {
    dashboard: spotifyDashboardInitialState,
}

const reducerStateModify = (state: SpotifyStoreState, action: any) => {
    return {
        ...state,
        dashboard: {
            ...state.dashboard,
            ...action.object
        }
    }
}

const spotifyReducer: Reducer<SpotifyStoreState, SpotifyActionTypes> = (state = spotifyInitialState, action: SpotifyActionTypes) => {
    switch (action.type) {
        case GET_SPOTIFY_TABLE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    table_get: {
                        object: action.object,
                        error: action.error
                    }
                }
            }
        case CLEAR_SPOTIFY:
            return reducerStateModify(state, action)
        case POST_SPOTIFY_EMAILS:
            return reducerStateModify(state, action)
        default:
            return state
    }
}

export default spotifyReducer

