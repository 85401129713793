import { PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO, ProductsValues } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk } from "../../types"


/// FOOD_TAG_POST

export interface FoodTagUpdateableDTO {
    name: string
}

export interface FoodTagDTO extends FoodTagUpdateableDTO {
    id: number
}

export const TAG_POST = 'TAG_POST'

export interface FoodTagPostParams {
    dto: FoodTagUpdateableDTO

}

export const foodTagPost = (params: FoodTagPostParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/tags';

        return callPostDispatchedApiSameName<FoodTagDTO, FoodStoreState, FoodTagUpdateableDTO>(
            apiService, path, dispatch, TAG_POST, params.dto);

    }
}

export interface FoodTagPostStoreFields extends PostObjectStoreFields<FoodTagDTO> { }

export interface FoodTagPostAction extends FoodTagPostStoreFields {
    type: typeof TAG_POST
}