import { DeleteObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callDeleteDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO, ProductsValues } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk } from "../../types"

/// PRODUCT_DELETE


export const PRODUCT_DELETE = 'PRODUCT_DELETE'

export interface ProductDeleteParams {
    id: number

}

export const productDelete = (params: ProductDeleteParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/product/' + params.id

        return callDeleteDispatchedApiSameName<FoodStoreState>(
            apiService, path, dispatch, PRODUCT_DELETE, params.id);

    }
}

export interface ProductDeleteStoreFields extends DeleteObjectStoreFields { }

export interface ProductDeleteAction extends ProductDeleteStoreFields {
    type: typeof PRODUCT_DELETE
}