import { PutObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk } from "../../types"
import { MealsPlanEntryUpdateableDTO, MealsPlanEntryDTO } from "./mealsPlanEntryPost"


/// MEALS_PLAN_ENTRY_PUT

export const MEALS_PLAN_ENTRY_PUT = 'MEALS_PLAN_ENTRY_PUT'

export interface MealsPlanEntryPutParams extends ApiCallBaseData  {
    dto: MealsPlanEntryUpdateableDTO
    planEntryId: number
}

export const mealsPlanEntryPut = (params: MealsPlanEntryPutParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/' + params.dto.mealsPlanId + '/entry/' + params.planEntryId;

        return callPutDispatchedApiSameName<MealsPlanEntryDTO, FoodStoreState, MealsPlanEntryUpdateableDTO, MealsPlanEntryPutParams>(
            apiService, path, dispatch, MEALS_PLAN_ENTRY_PUT, params.dto, params);

    }
}

export interface MealsPlanEntryPutStoreFields extends PutObjectStoreFieldsWithParams<MealsPlanEntryDTO,MealsPlanEntryPutParams> { }

export interface MealsPlanEntryPutAction extends MealsPlanEntryPutStoreFields {
    type: typeof MEALS_PLAN_ENTRY_PUT
}