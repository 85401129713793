import { ApiCallBaseData, callPostDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { BudgetStoreState, BudgetThunk } from "../../types"
import { PostObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"


export interface BudgetPlanUptadeableDTO {
    amount: number
    dated: string
    isMonthly: boolean
    bankingTransactionCategoryId: number
    comments: string
}

export interface BudgetPlanDTO extends BudgetPlanUptadeableDTO {
    id: number
}

export interface BudgetPlanInsertDTO {
    plans: BudgetPlanUptadeableDTO[]
}

export interface BudgetPlansDTO {
    plans: BudgetPlanDTO[]
}

/// BUDGET_PLANS_POST

export const BUDGET_PLANS_POST = 'BUDGET_PLANS_POST'

export interface BudgetPlansPostParams extends ApiCallBaseData  {
    dto: BudgetPlanInsertDTO
}

export const budgetPlansPost = (params: BudgetPlansPostParams): BudgetThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/api/budget/plan'

        return callPostDispatchedApiSameName<BudgetPlansDTO, BudgetStoreState, BudgetPlanInsertDTO, BudgetPlansPostParams>(
            apiService, path, dispatch, BUDGET_PLANS_POST, params.dto, params);

    }
}


export interface BudgetPlansPostStoreFields extends PostObjectStoreFieldsWithParams<BudgetPlansDTO,BudgetPlansPostParams> { }

export interface BudgetPlansPostAction extends BudgetPlansPostStoreFields {
    type: typeof BUDGET_PLANS_POST
}