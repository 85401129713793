import { DeleteObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callDeleteDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { WurdStoreState, WurdThunk } from "../../wurdStoreTypes"

/// WORD_DELETE


export const WORD_DELETE = 'WORD_DELETE'

export interface WordDeleteParams extends ApiCallBaseData {
    id: number
}

export const wordDelete = (params: WordDeleteParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/word/' + params.id

        return callDeleteDispatchedApiSameName<WurdStoreState>(
            apiService, path, dispatch, WORD_DELETE, params.id);

    }
}

export interface WordDeleteStoreFields extends DeleteObjectStoreFields { }

export interface WordDeleteAction extends WordDeleteStoreFields {
    type: typeof WORD_DELETE
}