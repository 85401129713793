import { BankingStoreState, BankingThunk } from "../../types"
import { callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { PutObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { BankingTransactionWithAccountDTO } from "./bankingTransactionsGet"



export interface BankingTransactionUploadDTO {
    bankingTransactionCategoryId: number
    comments?: string
}

export const BANKING_TRANSACTION_PUT = 'BANKING_TRANSACTION_PUT'

export interface BankingTransactionPutParams {
    originTransactionId: number
    dto: BankingTransactionUploadDTO
}



export const bankingTransactionPut = (params: BankingTransactionPutParams): BankingThunk => {
    return (dispatch, _getStore, { apiService }) => {


        var path: string = '/banking/transactions/' + params.originTransactionId


        return callPutDispatchedApiSameName<BankingTransactionWithAccountDTO, BankingStoreState, BankingTransactionUploadDTO, BankingTransactionPutParams>(
            apiService, path, dispatch, BANKING_TRANSACTION_PUT, params.dto, params);


    }
}

export interface BankingTransactionPutStoreFields extends PutObjectStoreFieldsWithParams<BankingTransactionWithAccountDTO, BankingTransactionPutParams> { }

export interface BankingTransactionPutAction extends BankingTransactionPutStoreFields {
    type: typeof BANKING_TRANSACTION_PUT
}

