



import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { BankingThunk, BankingStoreState } from "../../types"

// BANKING_DASHBOARD_GENERAL_GET

export const BANKING_DASHBOARD_GENERAL_GET = 'BANKING_DASHBOARD_GENERAL_GET'

export interface BankingGeneralDashboard {
    rorBalanceSum: number
    rorBlockedSum: number
    creditBalanceSum: number
    creditBlockedSum: number
    savingsBalanceSum: number
    savingsBlockedSum: number
}

export const bankingDashboardGeneralGet = (params: ApiCallBaseData): BankingThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/banking/dashboard/general'

        return callGetDispatchedApiSameName<BankingGeneralDashboard, BankingStoreState, void, ApiCallBaseData>(
            apiService, path, dispatch, BANKING_DASHBOARD_GENERAL_GET, undefined, params);

    }
}

export interface GetBankingDashboardGeneralStoreFields extends GetObjectStoreFieldsWithParams<BankingGeneralDashboard, ApiCallBaseData> { }

export interface GetBankingDashboardGeneralAction extends GetBankingDashboardGeneralStoreFields {
    type: typeof BANKING_DASHBOARD_GENERAL_GET
}