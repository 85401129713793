import { PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO, ProductsValues } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk } from "../../types"
import { DishRecipeDTO } from "./types"

/// DISH_RECIPE_PUT

export interface DishRecipeRichTextUpdateDTO {
    recipeRichText: string
}

export const DISH_RECIPE_PUT = 'DISH_RECIPE_PUT'

export interface DishRecipePutParams {
    id: number
    dto: DishRecipeRichTextUpdateDTO

}

export const dishRecipePut = (params: DishRecipePutParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/dishes/' + params.id + '/recipe'

        return callPutDispatchedApiSameName<ProductsDTO, FoodStoreState, DishRecipeRichTextUpdateDTO>(
            apiService, path, dispatch, DISH_RECIPE_PUT, params.dto);

    }
}

export interface DishRecipePutStoreFields extends PutObjectStoreFields<DishRecipeDTO> { }

export interface DishRecipePutAction extends DishRecipePutStoreFields {
    type: typeof DISH_RECIPE_PUT
}