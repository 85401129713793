import { Reducer } from 'redux'
import { reducerStateModifySubstore } from 'src/redux/reducers/reducerStateModifySubstore'
import { TasksActionTypes, TasksStoreState } from './types'
import { TASKS_SYNC_GET } from './search/store/tasksSyncGet'
import { TASKS_GET } from './search/store/tasksGet'



export const tasksInitialState: TasksStoreState = {
    search: {}
}

export const TasksSearchActionNames: string[] =
    [TASKS_SYNC_GET, TASKS_GET]

const tasksReducer: Reducer<TasksStoreState, TasksActionTypes> = (state = tasksInitialState, action: TasksActionTypes) => {

    if (TasksSearchActionNames.includes(action.type))
        return reducerStateModifySubstore(state, action, "search")
    else
        return state

}

export default tasksReducer

