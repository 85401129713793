import { PaginatedList, PaginatedListParams } from "src/interfaces/PaginatedList"
import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { concatApiUrl } from "src/utility/converters"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk } from "src/views/food/types"
import { ProductUnitConversionUpdateableDTO } from "./productConversionPost"


export interface ProductUnitConversionsDTO {
    convertions: ProductUnitConversionDTO[]
}

export interface ProductUnitConversionDTO extends ProductUnitConversionUpdateableDTO {
    id: number
    userId: number
}


export const PRODUCT_CONVERSION_LIST_GET = 'PRODUCT_CONVERSION_LIST_GET'

export interface ProductConversionListGetParams extends ApiCallBaseData {
    productId: number

}

export const productConversionListGet = (params: ProductConversionListGetParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/product/' + params.productId + "/conversion"

        return callGetDispatchedApiSameName<ProductUnitConversionsDTO, FoodStoreState, void, ProductConversionListGetParams>(
            apiService, path, dispatch, PRODUCT_CONVERSION_LIST_GET, undefined, params);

    }
}

export interface ProductConversionListGetStoreFields extends GetObjectStoreFieldsWithParams<ProductUnitConversionsDTO, ProductConversionListGetParams> { }

export interface ProductConversionListGetAction extends ProductConversionListGetStoreFields {
    type: typeof PRODUCT_CONVERSION_LIST_GET
}