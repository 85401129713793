import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { DishRecipeDTO } from "../../dish/store/types"
import { FoodStoreState, FoodThunk } from "../../types"
import { MealsPlanDTO } from "./mealsPlanGet"


export interface MealsPlanDailyMacroDTO {
    date: string
    ntrEnergy: number
    ntrPrtn: number
    ntrFat: number
    ntrCrbhdrt: number
    ntrFiber: number
    ntrSodium: number
    ntrNkt: number
}

export interface MealsPlanDailyMacroListDTO {
    entries: MealsPlanDailyMacroDTO[]
}

/// MEALS_PLAN_GET_MACROS

export const MEALS_PLAN_GET_MACROS = 'MEALS_PLAN_GET_MACROS'

export interface MealsPlanGetMacrosParams extends ApiCallBaseData {
    id: number

}

export const mealsPlanGetMacros = (params: MealsPlanGetMacrosParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/' + params.id + '/macros'

        return callGetDispatchedApiSameName<MealsPlanDailyMacroListDTO, FoodStoreState, void, MealsPlanGetMacrosParams>(
            apiService, path, dispatch, MEALS_PLAN_GET_MACROS, undefined, params);

    }
}

export interface MealsPlanGetMacrosStoreFields extends GetObjectStoreFieldsWithParams<MealsPlanDailyMacroListDTO, MealsPlanGetMacrosParams> { }

export interface MealsPlanGetMacrosAction extends MealsPlanGetMacrosStoreFields {
    type: typeof MEALS_PLAN_GET_MACROS
}