import { DeleteObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callDeleteDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { FoodStoreState, FoodThunk } from "../../types"

/// DISH_DELETE


export const DISH_DELETE = 'DISH_DELETE'

export interface DishDeleteParams {
    id: number

}

export const dishDelete = (params: DishDeleteParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/dishes/' + params.id

        return callDeleteDispatchedApiSameName<FoodStoreState>(
            apiService, path, dispatch, DISH_DELETE, params.id);

    }
}

export interface DishDeleteStoreFields extends DeleteObjectStoreFields { }

export interface DishDeleteAction extends DishDeleteStoreFields {
    type: typeof DISH_DELETE
}