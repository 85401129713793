import { DefaultValues } from "react-hook-form";
import { FormClaveNumeric } from "src/interfaces/Watch";
import { ReactSelectNumericOptionType } from "src/views/banking/shared/types/SelectOptionTypes";
import dayjs from 'dayjs'

export const printMoneyLocalised = (amount: number | undefined): string | undefined => {
    if (!amount || amount === 0) return "0";
    return amount.toLocaleString('pl-PL', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
}

export enum ValueStyle {
    FractionsTrimmedIfZero,
    NoFractions
}


export const printValueLocalised = (
    amount: number | undefined,
    style: ValueStyle = ValueStyle.FractionsTrimmedIfZero
): string | undefined => {
    let options: Intl.NumberFormatOptions = {}

    if (style === ValueStyle.NoFractions) {
        options.maximumFractionDigits = 0
        options.minimumFractionDigits = 0
    } else {
        options.maximumFractionDigits = 2
        options.minimumFractionDigits = 0
    }

    if (!amount || amount === 0) return "0";
    return amount.toLocaleString('pl-PL', options)
}



export const printCardNumber = (no: string | undefined): string | undefined => {
    if (!no) return undefined;
    return no.match(new RegExp('.{1,' + 4 + '}', 'g'))?.join(" ");
}

export const printDateFromStringLocal = (no: string | undefined): string | undefined => {
    if (!no) return undefined;
    return new Date(no).toLocaleDateString();
}

export const printDateLocal = (no: Date | undefined): string | undefined => {
    if (!no) return undefined;
    return no.toLocaleDateString();
}


export const printDateISO = (dt: Date): string => {
    return dt.toISOString().split('T')[0];
}





export function parsePolishFloat(str: string): number {
    return parseFloat(str
        .replace(/\s+/g, '') // usuwanie potencjalnych spacji, enterów, tabulacji
        .replace(',', '.'));
}


export function polishFloatToStandardString(n: number): string {
    return n.toString().replace(',', '.');
}


export function dateToYearMonthPrint(b: Date): string {
    return b.getFullYear().toString() + '.' + (b.getMonth() + 1).toString().padStart(2, '0')
}


export function isDefined(o: any): boolean {
    return o !== undefined && o !== null
}


/// Date converters

export function dateLastDayOfPreviousMonth(d: Date): Date {
    return new Date(d.getFullYear(), d.getMonth(), 0)
}

export function dateFirstDayOfNextMonth(d: Date): Date {
    return new Date(d.getFullYear(), d.getMonth() + 1, 1)
}

export function monthDiff(d1: Date, d2: Date) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

/// Number printer

export const printTimeFromSeconds = (timeInSeconds: number): string => {
    var prnt = ""
    var val = timeInSeconds;
    var factor = Math.floor(val / (60 * 60)) // godziny
    if (factor > 0) {
        val -= factor * 60 * 60
        prnt += factor + "h "
    }

    factor = Math.floor(val / 60) // minuty
    if (factor > 0) {
        val -= factor * 60
        prnt += factor + "m "
    }

    prnt += val + "s" // sekundy
    return prnt;
}


/// API URL appender

export const concatApiUrl = (currentUrl: string, v: any, keyName: string, defaultValue?: any): string => {
    var newUrl = currentUrl;
    const val = v ?? defaultValue;
    if (val === undefined || val === null) return newUrl

    var appender = '?'
    if (newUrl.indexOf("?") !== -1)
        appender = '&'

    appender += keyName + "="

    if (typeof val === 'string' || val instanceof String)
        return newUrl + appender + val
    else if (typeof val === 'number')
        return newUrl + appender + val
    else if (typeof val === 'boolean')
        return newUrl + appender + (val ? 'true' : 'false')
    else throw new Error("Nieobsłużony typ")

}

/// Form parsers and converters

export function dateTimeFormToApi(d: Date | undefined): string | undefined {
    if (d === undefined) return undefined
    // return d.toLocaleDateString('en-CA');
    return d.toISOString();
    // return dayjs(d).format()
}

export function dateFormToApi(d: Date | undefined): string | undefined {
    if (d === undefined) return undefined
    // return d.toLocaleDateString('en-CA');
    // return d.toDateString();
    return dayjs(d).format('YYYY-MM-DD');
}

export function numberFormToApi(d: FormClaveNumeric | undefined): number | undefined {
    return d === undefined ? undefined : parseFloat(d.replace(',', '.'));
}

export function numberFormFromApi(d: number | undefined): FormClaveNumeric | undefined {
    return d === undefined || d === null ? undefined : d.toString().replace('.', ',');;
}

export function textFormToApi(d: string | undefined): string | undefined {
    return d === undefined ? undefined : d.length === 0 ? undefined : d;
}

export function reactSelectNumericOptionFormToApi(d: ReactSelectNumericOptionType | undefined | DefaultValues<ReactSelectNumericOptionType>): number | undefined {
    return d === undefined || d === null ? undefined : d.id
}

export function booleanFormToApi(d: boolean | undefined, ifFalseReturnUndefined: boolean = false): boolean | undefined {
    if (d === undefined || d === null) return undefined
    if (ifFalseReturnUndefined && !d) return undefined
    return d;
}


export function checkEmpty(v: any): boolean {
    if (v === undefined || v === null) return true
    else if (typeof v === 'string' || v instanceof String)
        return v.length === 0
    else if (typeof v === 'number')
        return Number.isNaN(v)
    else if (typeof v === 'boolean')
        return false
    else throw new Error("Nieobsłużony typ " + typeof v)
}