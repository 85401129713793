import { PostObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { callPostDispatchedApiSameName, callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { ProductsDTO, ProductsValues } from "../../dicts/store/types"
import { FoodStoreState, FoodThunk } from "../../types"
import { MealsPlanDTO, MealsPlanUpdateableDTO } from "./mealsPlanGet"


/// MEALS_PLAN_PUT



export const MEALS_PLAN_PUT = 'MEALS_PLAN_PUT'

export interface MealsPlanPutParams {
    dto: MealsPlanUpdateableDTO
    id: number
}

export const mealsPlanPut = (params: MealsPlanPutParams): FoodThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/food/meals_plan/' + params.id;
        
        return callPutDispatchedApiSameName<MealsPlanDTO, FoodStoreState, MealsPlanUpdateableDTO>(
            apiService, path, dispatch, MEALS_PLAN_PUT, params.dto);

    }
}

export interface MealsPlanPutStoreFields extends PostObjectStoreFields<MealsPlanDTO> { }

export interface MealsPlanPutAction extends MealsPlanPutStoreFields {
    type: typeof MEALS_PLAN_PUT
}