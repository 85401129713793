import { BankingStoreState, BankingThunk } from "../../types"
import { callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { GetObjectStoreFieldsWithParams, PutObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { BankingTransactionWithAccountDTO } from "./bankingTransactionsGet"



export interface BankingTransactionSplitDTO {
    secondTransactionAmount: number
}

export const BANKING_TRANSACTION_SPLIT_PUT = 'BANKING_TRANSACTION_SPLIT_PUT'

export interface BankingTransactionSplitPutParams {
    originTransactionId: number
    dto: BankingTransactionSplitDTO
}



export const bankingTransactionSplitPut = (params: BankingTransactionSplitPutParams): BankingThunk => {
    return (dispatch, _getStore, { apiService }) => {


        var path: string = '/banking/transactions/' + params.originTransactionId+ "/split"


        return callPutDispatchedApiSameName<BankingTransactionWithAccountDTO, BankingStoreState, BankingTransactionSplitDTO, BankingTransactionSplitPutParams>(
            apiService, path, dispatch, BANKING_TRANSACTION_SPLIT_PUT, params.dto, params);


    }
}

export interface BankingTransactionSplitPutStoreFields extends PutObjectStoreFieldsWithParams<BankingTransactionWithAccountDTO, BankingTransactionSplitPutParams> { }

export interface BankingTransactionSplitPutAction extends BankingTransactionSplitPutStoreFields {
    type: typeof BANKING_TRANSACTION_SPLIT_PUT
}

