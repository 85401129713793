export const reducerStateModifySubstore = (state: any, action: any, substoreName?: string) => {

    let reducer_object = { ...state } as any

    if (substoreName !== undefined) {

        let object_inner = {
            ...(state as any)[substoreName],
            ...action.object
        } as any;

        reducer_object[substoreName] = object_inner
    } else {
        reducer_object = {
            ...state,
            ...action.object
        } as any
    }

    return reducer_object
}