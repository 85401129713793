import { PostObjectStoreFields, PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"




export interface FinancialProductPersonalAccountUpdateableDTO {
    iban?: string
    currency?: string
}

export interface FinancialProductPersonalAccountDTO extends FinancialProductPersonalAccountUpdateableDTO{
    id: number
}


// POST

export const POST_FINANCIAL_PRODUCT_PERSONAL_ACCOUNT = 'POST_FINANCIAL_PRODUCT_PERSONAL_ACCOUNT'

export interface PostFinancialProductPersonalAccountFields extends PostObjectStoreFields<FinancialProductPersonalAccountDTO> { }

export interface PostFinancialProductPersonalAccountAction extends PostFinancialProductPersonalAccountFields {
    type: typeof POST_FINANCIAL_PRODUCT_PERSONAL_ACCOUNT
}

// PUT

export const PUT_FINANCIAL_PRODUCT_PERSONAL_ACCOUNT = 'PUT_FINANCIAL_PRODUCT_PERSONAL_ACCOUNT'

export interface PutFinancialProductPersonalAccountFields extends PutObjectStoreFields<FinancialProductPersonalAccountDTO> { }

export interface PutFinancialProductPersonalAccountAction extends PutFinancialProductPersonalAccountFields {
    type: typeof PUT_FINANCIAL_PRODUCT_PERSONAL_ACCOUNT
}


// CLEAR_PRODUCTS_PERSONAL_ACCOUNTS

export const CLEAR_PRODUCTS_PERSONAL_ACCOUNTS = 'CLEAR_PRODUCTS_PERSONAL_ACCOUNTS'

export interface ClearProductsPersonalAccountsAction {
    type: typeof CLEAR_PRODUCTS_PERSONAL_ACCOUNTS
}

/// STORE

export interface ProductPersonalAccountStoreState {
    post?: PostFinancialProductPersonalAccountFields
    put?: PutFinancialProductPersonalAccountFields
}


export const productPersonalAccountInitialState: ProductPersonalAccountStoreState = {
}

export type ProductPersonalAccountActionTypes = PostFinancialProductPersonalAccountAction | PutFinancialProductPersonalAccountAction | ClearProductsPersonalAccountsAction;
