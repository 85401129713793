import { clearPartialSameNameGeneric } from "src/redux/actions/clearPartialGeneric"
import { FoodStoreState, FoodThunk } from "../../types"
import { CLEAR_FOOD_DISH, DishUploadDTO } from "./types"



export const preparePostPutBody = (dish: DishUploadDTO): FormData => {
  let formData = new FormData();
  formData.append('Name', dish.name);
  formData.append('IsBreakfast', dish.isBreakfast.toString());
  formData.append('IsScndBreakfast', dish.isScndBreakfast.toString());
  formData.append('IsLunch', dish.isLunch.toString());
  formData.append('IsTea', dish.isTea.toString());
  formData.append('IsSupper', dish.isSupper.toString());
  formData.append('Servings', dish.servings.toString());
  formData.append('ServingsUnitId', dish.servingsUnitId.toString());
  
  dish.tags.forEach(t => {
    formData.append('Tags[]', t.toString());    
  });

  if (dish.url) formData.append('Url', dish.url);
  if (dish.description) formData.append('Description', dish.description);
  if (dish.formFile) formData.append('FormFile', dish.formFile);
  return formData;
}


export const clearFoodDishPartial = (part: string): FoodThunk => {
  return (dispatch) => {
    clearPartialSameNameGeneric<FoodStoreState>(part, CLEAR_FOOD_DISH, dispatch);
  }
}

