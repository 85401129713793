import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { TasksStoreState, TasksThunk } from "../../types"

export interface SyncAllUserTasksDTO {
    listsCount: number
    tasksCount: number
}


/// TASKS_SYNC_GET

export const TASKS_SYNC_GET = 'TASKS_SYNC_GET'

export interface TasksSyncGetParams extends ApiCallBaseData {
}

export const tasksSyncGet = (params: TasksSyncGetParams): TasksThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/tasks/sync'

        return callGetDispatchedApiSameName<SyncAllUserTasksDTO, TasksStoreState, void, TasksSyncGetParams>(
            apiService, path, dispatch, TASKS_SYNC_GET, undefined, params);

    }
}

export interface TasksSyncGetStoreFields extends GetObjectStoreFieldsWithParams<SyncAllUserTasksDTO, TasksSyncGetParams> { }

export interface TasksSyncGetAction extends TasksSyncGetStoreFields {
    type: typeof TASKS_SYNC_GET
}