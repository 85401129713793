import { PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callPutDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { UserStoreState, UserThunk } from "../types"
import { UserDTO } from "./groupGet"


/// USER_GOOGLE_CODE_PUT

export interface UserGoogleCodePutDTO {
    googleCode: string
}

export const USER_GOOGLE_CODE_PUT = 'USER_GOOGLE_CODE_PUT'

export interface UserGoogleCodePutParams extends ApiCallBaseData {
    dto: UserGoogleCodePutDTO

}

export const userGoogleCodePut = (params: UserGoogleCodePutParams): UserThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/user/google_code';
        
        return callPutDispatchedApiSameName<UserDTO, UserStoreState, UserGoogleCodePutDTO>(
            apiService, path, dispatch, USER_GOOGLE_CODE_PUT, params.dto);

    }
}

export interface UserGoogleCodePutStoreFields extends PutObjectStoreFields<UserDTO> { }

export interface UserGoogleCodePutAction extends UserGoogleCodePutStoreFields {
    type: typeof USER_GOOGLE_CODE_PUT
}