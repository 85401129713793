import { GetObjectStoreFieldsWithParams } from "src/redux/interfaces/GenericStoreApiResults"
import { ApiCallBaseData, callGetDispatchedApiSameName } from "src/utility/DispatchWrappers"
import { WordDTO } from "../../words_list/store/wordsListTypes"
import { WurdThunk, WurdStoreState } from "../../wurdStoreTypes"


export interface WordDuplicateDTO {
    words: WordDTO[]
    basicForm: string
    partOfSpeechId: number
}

export interface WordDuplicateDashboardDTO {
    wordDuplicates: WordDuplicateDTO[]
}

/// WORD_DUPLICATE_GET

export const WORD_DUPLICATE_GET = 'WORD_DUPLICATE_GET'

export interface WordDuplicateGetParams extends ApiCallBaseData {

}

export const wordDuplicateGet = (params: WordDuplicateGetParams): WurdThunk => {
    return (dispatch, _getStore, { apiService }) => {

        var path: string = '/wurd/word/duplicate'

        return callGetDispatchedApiSameName<WordDuplicateDashboardDTO, WurdStoreState, void, WordDuplicateGetParams>(
            apiService, path, dispatch, WORD_DUPLICATE_GET, undefined, params);

    }
}

export interface WordDuplicateGetStoreFields extends GetObjectStoreFieldsWithParams<WordDuplicateDashboardDTO, WordDuplicateGetParams> { }

export interface WordDuplicateGetAction extends WordDuplicateGetStoreFields {
    type: typeof WORD_DUPLICATE_GET
}