import { DeleteObjectStoreFields, PostObjectStoreFields, PutObjectStoreFields } from "src/redux/interfaces/GenericStoreApiResults"
import { DishIngredientDTO, DishVariantDTO } from "./types"


export interface DishVariantUpdatableDTO {
    orderNo: number
    multiplier?: number
    name: string
}

/// DISH_VARIANT_POST

// DishVariantUpdatableDTO -> DishVariantDTO

export const DISH_VARIANT_POST = 'DISH_VARIANT_POST'

export interface DishVariantPostStoreFields extends PostObjectStoreFields<DishVariantDTO> {
}

export interface DishVariantPostListsAction extends DishVariantPostStoreFields {
    type: typeof DISH_VARIANT_POST
}

/// DISH_VARIANT_PUT

// DishVariantUpdatableDTO -> DishVariantDTO

export const DISH_VARIANT_PUT = 'DISH_VARIANT_PUT'

export interface DishVariantPutStoreFields extends PutObjectStoreFields<DishVariantDTO> {
}

export interface DishVariantPutAction extends DishVariantPutStoreFields {
    type: typeof DISH_VARIANT_PUT
}

/// DISH_VARIANT_DELETE

// DishVariantUpdatableDTO -> DishVariantDTO

export const DISH_VARIANT_DELETE = 'DISH_VARIANT_DELETE'

export interface DishVariantDeleteStoreFields extends DeleteObjectStoreFields {
}

export interface DishVariantDeleteAction extends DishVariantDeleteStoreFields {
    type: typeof DISH_VARIANT_DELETE
}

export interface DishIngredientUpdatableDTO {
    quantity: number,
    unitId: number,
    isOptional: boolean,
    additionalInfo?: string
    productId: number,
}

/// DISH_INGREDIENT_POST

// DishIngredientUpdatableDTO -> DishIngredientDTO

export const DISH_INGREDIENT_POST = 'DISH_INGREDIENT_POST'

export interface DishIngredientPostStoreFields extends PostObjectStoreFields<DishIngredientDTO> {
}

export interface DishIngredientPostAction extends DishIngredientPostStoreFields {
    type: typeof DISH_INGREDIENT_POST
}

/// DISH_INGREDIENT_PUT

// DishIngredientUpdatableDTO -> DishIngredientDTO

export const DISH_INGREDIENT_PUT = 'DISH_INGREDIENT_PUT'

export interface DishIngredientPutStoreFields extends PutObjectStoreFields<DishIngredientDTO> {
}

export interface DishIngredientPutAction extends DishIngredientPutStoreFields {
    type: typeof DISH_INGREDIENT_PUT
}

/// DISH_INGREDIENT_DELETE

// DishIngredientUpdatableDTO -> DishIngredientDTO

export const DISH_INGREDIENT_DELETE = 'DISH_INGREDIENT_DELETE'

export interface DishIngredientDeleteStoreFields extends DeleteObjectStoreFields {
}

export interface DishIngredientDeleteAction extends DishIngredientDeleteStoreFields {
    type: typeof DISH_INGREDIENT_DELETE
}

export type IngredientsActionTypes = DishVariantPutAction | DishVariantPostListsAction | DishVariantDeleteAction
    | DishIngredientPostAction | DishIngredientPutAction | DishIngredientDeleteAction

